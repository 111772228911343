import log from './log';

export default class Timeline {
  id: string;
  isPaused: boolean = false;
  actions: any[] = [];
  isFastForwarding: boolean = false;

  constructor() {
    this.id = Math.ceil(Math.random() * Date.now()).toString(36);

    log(`Timeline created with id "${this.id}"`, 'Timeline');
  }

  add(
    action: (...args: any) => void | Promise<void>,
    duration: number
  ): Timeline {
    this.actions.push({ action, duration });

    return this;
  }

  async play(): Promise<void> {
    if (this.isPaused) {
      log('Timeline is paused, not playing next action', 'Timeline');
      return;
    }

    const actionToExecute = this.actions.shift();

    if (!actionToExecute) return;

    await actionToExecute.action();

    if (this.actions.length)
      setTimeout(
        async () => await this.play(),
        this.isFastForwarding ? 10 : actionToExecute.duration
      );
  }

  public clear(): Timeline {
    if (!this.isPaused)
      throw new Error('Cannot clear a timeline that is not paused');

    this.actions = [];

    return this;
  }

  public pause(): Timeline {
    this.isPaused = true;

    log('Timeline paused', 'Timeline');

    return this;
  }

  public resume(): Timeline {
    this.isPaused = false;

    log('Timeline resumed', 'Timeline');

    return this;
  }

  public setFastForwarding(value: boolean): Timeline {
    this.isFastForwarding = value;

    return this;
  }
}
