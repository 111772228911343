import { motion } from 'framer-motion';
import { Howl } from 'howler';
import SendIcon from './send-icon';
import AnswerPicker from './answer-picker';
import { useGame } from '../../store/game';
import buildAssetPath from '../../core/buildAssetPath';
import clsx from 'clsx';
import { useMessages } from '../../store/messages';

const Statuses = {
  Idle: 0,
  Writing: 1,
  WaitingForAnswer: 2,
};

const ConversationInput = ({
  questionId,
  status,
  availableAnswers,
  submitAnswer,
  answer,
  setAnswer,
}) => {
  const pickAnswerSFX = new Howl({
    src: [buildAssetPath('sfx/pick-answer.mp3')],
    volume: 0.6,
  });

  const isPlanFeasible = useGame(({ isPlanFeasible, currentPlan }) =>
    isPlanFeasible(currentPlan)
  );
  const { isSoundOn } = useGame(({ isSoundOn }) => ({
    isSoundOn,
  }));
  const t = useMessages(({ print }) => print);

  return (
    <div
      className="absolute bottom-0 left-0 flex flex-row-reverse flex-grow w-full h-20 max-h-full gap-4 px-6 py-2 2xl:px-8 bg-neutral-200"
      style={{ display: 'flex' }}
    >
      <motion.button
        disabled={!answer}
        className="w-10 h-10 2xl:w-[48px] 2xl:h-[48px] my-auto ml-auto rounded-full flex-none flex justify-center items-center cursor-pointer bg-blue-600 relative"
        style={{ display: 'flex', opacity: answer ? 1 : 0.2 }}
        whileHover={{
          scale: 1.05,
          transition: { duration: 0.2 },
        }}
        whileTap={{
          scale: 0.98,
          transition: { duration: 0.1 },
        }}
        animate={{ scale: 1 }}
        onClick={submitAnswer}
      >
        {answer && (
          <div className="absolute w-full h-full bg-blue-600 rounded-full animate-ping" />
        )}
        <span className="opacity-60 invert">
          <SendIcon />
        </span>
      </motion.button>
      <div
        className="flex items-center justify-center flex-grow p-1 2xl:p-2 bg-gray-400/20 rounded-xl"
        style={{ display: 'flex' }}
      >
        {status === Statuses.WaitingForAnswer && (
          <div
            className={clsx(
              'relative w-full m-auto font-bold text-center animate animate-pulse',
              isPlanFeasible ? 'cursor-pointer' : ''
            )}
          >
            {(questionId === 'draft-plan-ready' ||
              questionId === 'draft-plan-confirm' ||
              questionId === 'executive-plan-ready' ||
              questionId === 'executive-plan-confirm') &&
            !isPlanFeasible ? (
              <span className="leading-none text-center text-red-400">
                {t('chat-chat-answer-label-fix-your-plan-first')}
              </span>
            ) : (
              <AnswerPicker
                options={availableAnswers}
                answer={answer}
                handleMessageSelection={_message => {
                  if (isSoundOn()) pickAnswerSFX.play();

                  setAnswer(_message);
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ConversationInput;
