// NOTE: Translations are stored here:
// https://console.firebase.google.com/project/change-u-4716d/storage/change-u-4716d.appspot.com/files/~2Fmessages~2Fprototype-alpha.

import { FluentBundle, FluentResource } from '@fluent/bundle';
import { Message } from '@fluent/bundle/esm/ast';
import { ILogger } from '../concepts/logger';
import { StoreSlice } from '../../utils/store-slice';
import { IMessages } from '../concepts/messages';
import { IResourceDirectoryApi } from '../remote/api';

interface IPrivate {
  unsub: () => void;
  findMessage(id: string): Message;
}

export const create =
  (remote: IResourceDirectoryApi): StoreSlice<IMessages & IPrivate, ILogger> =>
  (set, get) => {
    return {
      unsub: () => {},
      idiom: 'en',

      changeIdiom(idiom: string) {
        set({ idiom });
      },

      languages: {
        it: new FluentBundle('it'),
        en: new FluentBundle('en'),
      },

      initializeIdiom(idiom: string, resource: FluentResource) {
        get().log(`Initializing \`${idiom}\` language resources...`);

        const idiomBundle = new FluentBundle(idiom);
        const errors = idiomBundle.addResource(resource);

        if (errors?.length)
          throw new Error(errors.map(({ message }) => message).join('\n'));

        set({
          languages: {
            ...get().languages,
            [idiom]: idiomBundle,
          },
        });

        get().log(`\`${idiom}\` language initialized!`);
      },

      init(opts) {
        get().log('`Messages` initialized');
        get().unsub();

        Object.entries(opts).forEach(([idiom, resourceURL]) =>
          remote
            .read(resourceURL)
            .then(resource => get().initializeIdiom(idiom, resource))
        );
      },

      findMessage(id: string) {
        return get().languages[get().idiom].getMessage(id);
      },

      print(
        messageId: string,
        context: Record<string, string | number | Date> = {}
      ) {
        const message = get().findMessage(messageId);
        if (!message) return `missing«${messageId}»`;
        return get().languages[get().idiom].formatPattern(
          message.value,
          context
        );
      },
    };
  };
