"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LedspEmulator = void 0;
const validate_game_flow_1 = require("./validate-game-flow");
class LedspEmulator {
    constructor(gamePlayInfoId, gameConcept) {
        this.gamePlayInfoId = gamePlayInfoId;
        this.gameConcept = gameConcept;
        this._events = [];
        this.expectedEvents = [];
        this.expectedEvents = gameConcept.gameFlow.reduce((acc, step) => {
            const phase = gameConcept.phases.find((p) => p.name == step.phase);
            for (const stage of phase.stages)
                acc.push({ step: step.id, stage });
            return acc;
        }, []);
    }
    get storageKey() {
        return `games-progresses-events.${this.gamePlayInfoId}`;
    }
    get events() {
        return typeof window !== "undefined"
            ? JSON.parse(localStorage.getItem(this.storageKey) || "[]")
            : this._events;
    }
    gamePlayInfo(opts = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return Object.assign(defaultGamePlayInfo(this.gamePlayInfoId, this.gameConcept), opts);
        });
    }
    sendGameProgressEvent(event) {
        return __awaiter(this, void 0, void 0, function* () {
            (0, validate_game_flow_1.default)(this.gameConcept, this.events, this.expectedEvents, event, console.error);
            if (typeof window !== "undefined")
                localStorage.setItem(this.storageKey, JSON.stringify(this.events.concat(event)));
            else
                this._events.push(event);
        });
    }
}
exports.LedspEmulator = LedspEmulator;
const defaultGamePlayInfo = (id, gameConcept) => ({
    id,
    sessionId: "DEMO_SESSION",
    user: {
        id: "USER_DEMO",
        displayName: "Emulated user",
    },
    gameId: id,
    playerId: "P1_DEMO",
    team: {
        id: "T_DEMO",
        name: "Emulated team",
    },
    role: "Emulated role",
    settings: {
        playURL: "#",
        configuration: {
            playOptions: gameConcept.defaultPlayOptionsSet,
            players: [
                {
                    id: "P1_DEMO",
                    color: "red",
                    displayName: "Player 1",
                },
                {
                    id: "P2_DEMO",
                    color: "blue",
                    displayName: "Player 2",
                },
            ],
            returnPath: "#",
            gameResultsRegistryEndpoint: "",
        },
    },
});
