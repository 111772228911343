{
  "id": "Change U-1.0.0",
  "availablePlayOptions": [
    {
      "name": "scenarioName",
      "description": "",
      "format": "text",
      "required": true,
      "acceptedValues": ["neutral", "crisis"]
    }
  ],
  "availablePropsAndToolsSets": [
    {
      "name": "Change-U",
      "gameLaunchService": {
        "method": "POST",
        "url": "https://api.ledsp.va5.it/api/game-launcher/launch",
        "headers": {}
      },
      "gameService": {
        "method": "GET",
        "url": "https://api.ledsp.va5.it/api/game-launcher/involvements",
        "headers": {}
      },
      "gameLaunchInfoSetValidationEndpoint": {
        "method": "POST",
        "url": "https://ledsp-authority-staging-gtispat4va-ew.a.run.app/validate-launch-info",
        "headers": {
          "Content-Type": "application/json"
        }
      },
      "gameLaunchSettingsEndpoint": {
        "method": "POST",
        "url": "https://ledsp-authority-staging-gtispat4va-ew.a.run.app/settings",
        "headers": {
          "Content-Type": "application/json"
        }
      },
      "gameRoomConfigurationEndpoint": {
        "method": "POST",
        "url": "https://ledsp-authority-staging-gtispat4va-ew.a.run.app/game-room-configuration",
        "headers": {
          "Content-Type": "application/json"
        }
      },
      "gamePlayEndpoint": {
        "method": "GET",
        "url": "https://change-u-game.va5.it/"
      }
    }
  ],
  "defaultPlayOptionsSet": {
    "scenarioName": "neutral"
  },
  "name": "Change U",
  "playersPartyFormationInfo": {
    "sizeCriterion": {
      "kind": "size",
      "size": 5,
      "subKind": "number-of-members"
    },
    "minSize": 1,
    "maxSize": 5
  },
  "teamFormationInfo": {
    "availableTeamRoles": ["Role1"],
    "teamSizingCriteria": {
      "kind": "size",
      "size": 5,
      "subKind": "number-of-members"
    },
    "uniqRolePerTeam": false
  },
  "version": "1.0.0",
  "phases": [
    {
      "name": "Introduction",
      "expectedDurationInSeconds": {
        "min": 0,
        "max": 1000
      },
      "stages": ["Explanations", "Rules", "Portfolio"]
    },
    {
      "name": "PlanningAndFeedback",
      "expectedDurationInSeconds": {
        "min": 1000,
        "max": 2000
      },
      "stages": ["Planning", "Feedback"]
    },
    {
      "name": "Endgame",
      "expectedDurationInSeconds": {
        "min": 100,
        "max": 200
      },
      "stages": ["Outro"]
    }
  ],
  "gameFlow": [
    {
      "id": "Introduction",
      "phase": "Introduction",
      "description": "The goal of this phase is to introduce the players to the game."
    },
    {
      "id": "DraftPlan",
      "phase": "PlanningAndFeedback",
      "description": "Todo."
    },
    {
      "id": "ExecutivePlan",
      "phase": "PlanningAndFeedback",
      "description": "Todo."
    },
    {
      "id": "Endgame",
      "phase": "Endgame",
      "description": "Todo."
    }
  ]
}
