export const ExtractMultiLines = (text: string, className: string = '') =>
  text
    .split('\n')
    .map((el, i) => (
      <p
        key={i}
        className={className}
        dangerouslySetInnerHTML={{ __html: el }}
      />
    ));
