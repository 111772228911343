import { IGame } from '../concepts/game';
import { ILogger } from '../concepts/logger';
import { StoreSlice } from '../../utils/store-slice';

export const create: StoreSlice<ILogger & IPrivate, IGame> = (set, get) => {
  return {
    log: (msg: string) => {
      console.log(`»» [] ${msg}`);
    },
    error: (reason: string) => {
      console.log(`»! [] ${reason}`);
    },
  };
};

interface IPrivate {}
