import { IObservationRegistryApi, ObservationData } from './api';
import {
  Firestore,
  writeBatch,
  doc,
  collection,
  query,
  where,
  getDocs,
  DocumentData,
} from 'firebase/firestore';

export const observations = (db: Firestore): IObservationRegistryApi => ({
  async register(directoryId, observations) {
    const batch = writeBatch(db);
    observations.forEach(o => {
      batch.set(doc(db, `or`, btoa(o.observationId)), o);
    });

    await Promise.resolve(batch.commit());
  },

  async find(directoryId, querySpec) {
    const q = query(
      collection(db, `or`),
      where('objectId', '>=', querySpec.objectId),
      where('objectId', '<=', querySpec.objectId + '\uf8ff')
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map(d => d.data()).map(toObservationData);
  },
});

const toObservationData = (doc: DocumentData): ObservationData =>
  doc as ObservationData;
