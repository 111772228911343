import { ObservationData } from '../remote/api';
import micromatch from 'micromatch';

export interface IObservations {
  load(directoryId: string, objectIdPrefix: string): Promise<void>;
  save(directoryId: string): Promise<void>;
  clear(): void;
  inject(observations: ObservationData[]): void;

  find(objectIdPattern: string): Observation[];
  measure(
    objectId: string,
    metric: string,
    value: string | number | boolean
  ): void;
  describe(objectId: string, demographic: string, value: string): void;
}

export type Observation = {
  objectId: string;
} & { [key: string]: string | number };

export interface IObjectIdPattern {
  test(objectId: string): boolean;
}

export const createPattern = (spec: string): IObjectIdPattern => ({
  test: id => {
    return micromatch.isMatch(id, spec);
  },
});

export const toObservation = (data: ObservationData): Observation => ({
  objectId: data.objectId,
  ...data.demographics,
  ...data.metrics,
});
