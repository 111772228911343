import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import buildAssetPath from '../../core/buildAssetPath';
import { Documents } from '../../store/game/concepts/documents';
import { useMessages } from '../../store/messages';
import { icons } from './documents-data';

interface IProps {
  handleOpen: () => void;
  file: Documents;
}

const Document = ({ handleOpen, file }: IProps) => {
  const t = useMessages(({ print }) => print);

  return (
    <motion.div
      initial={{
        opacity: 0,
        filter: 'blur(8px) brightness(0.7)',
        scale: 0,
      }}
      animate={{
        opacity: 1,
        filter: 'blur(0px) brightness(1)',
        scale: 1,
      }}
      whileHover={{
        filter: 'blur(0px) brightness(1.05)',
        transition: { duration: 0.07 },
      }}
      transition={{ duration: 0.4, easing: 'easeInOut' }}
      className="flex flex-col w-12 space-y-2 cursor-pointer lg:w-16 xl:w-20 2xl:w-24 4xl:w-32"
      onClick={handleOpen}
    >
      <img
        src={buildAssetPath(`images/${icons[file] || 'sheet.png'}`)}
        alt={file}
        className="_nodrag"
      />
      <p
        className="font-sans text-sm font-semibold leading-tight text-center text-white"
        style={{
          textShadow:
            '1px 1px 4px rgb(0 0 0 / 39%), 0px 1px 2px rgb(0 0 0 / 39%), 0px 1px 2px rgb(0 0 0 / 39%), 0px 1px 2px rgb(0 0 0), 0px 1px 2px rgb(0 0 0 / 19%), -1px -1px 0 Black, 1px -1px 0 Black, -1px 1px 0 Black, 1px 1px 0 Black',
        }}
      >
        {t(file)}
        {file === 'minuta' &&
          ` ${dayjs().subtract(3, 'day').format('DD/MM/YYYY')}`}
      </p>
    </motion.div>
  );
};

export default Document;
