import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import clsx from 'clsx';
import { FC } from 'react';
import { useGame } from '../../../store/game';
import { useMessages } from '../../../store/messages';
import { CoinIcon } from '../../game/coins-icon';
import OrderNudge from './order-nudge';
import SortableItem from './sortable-item';

const SortableActionList: FC<{
  planId: string;
  actions: string[];
  onReorder: (event: { active; over }) => void;
  Item: FC<{ position: number; action: string }>;
  Before?: FC<{ position: number; action: string }>;
  After?: FC<{ position: number; action: string }>;
}> = ({ planId, actions, onReorder, Item, Before, After }) => {
  const sensors = useSensors(useSensor(PointerSensor));

  const isPlanSubmitted = useGame(({ isPlanSubmitted }) =>
    isPlanSubmitted(planId)
  );
  const excludeAction = useGame(
    ({ excludeActionFromPlan }) => excludeActionFromPlan
  );
  const isActionExcluded = useGame(s => s.isActionExcluded);
  const getActionName = useMessages(
    ({ print }) =>
      id =>
        print(`action-${id}-name`)
  );
  const actionCode = useGame(({ actionCode }) => actionCode);
  const actionCost = useGame(s => s.actionCost);
  const t = useMessages(({ print }) => print);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onReorder}
    >
      <SortableContext items={actions} strategy={verticalListSortingStrategy}>
        {actions.map(action => (
          <SortableItem
            key={action}
            id={action}
            isPlanSubmitted={isPlanSubmitted}
          >
            <b className="w-1/12">{actions.indexOf(action) + 1}°</b>{' '}
            <span
              title={getActionName(action)}
              className={clsx(
                'w-5/12 truncate flex flex-row items-center space-x-2',
                {
                  'line-through opacity-30': isActionExcluded(action),
                }
              )}
            >
              <b className="font-bold text-slate-700 text-xxs 2xl:text-xs 3xl:text-sm">{`[${actionCode(
                action
              )}]`}</b>
              <span className="text-xs 2xl:text-sm 3xl:text-base">
                {getActionName(action)}
              </span>
            </span>
            <div className="flex flex-row justify-end w-1/12 space-x-1">
              {actionCost(action)} <CoinIcon />
            </div>
            <div className="flex flex-row justify-end w-3/12 space-x-1">
              {actions
                .slice(actions.indexOf(action) + 1)
                .map(targetActionId => (
                  <OrderNudge
                    key={targetActionId}
                    actionId={action}
                    targetActionId={targetActionId}
                  />
                ))}
            </div>
            {!isPlanSubmitted && (
              <button
                className="z-10 float-right w-2/12 py-px font-sans font-medium text-red-800 uppercase transition-all duration-200 ease-in-out rounded 3xl:py-1 text-xxs 3xl:text-xs bg-white/90 hover:bg-red-200/60 hover:duration-75"
                onClick={event => {
                  event.stopPropagation();
                  event.preventDefault();

                  excludeAction(planId, action);
                }}
              >
                {t('plan-action-list-remove-item')}
              </button>
            )}
          </SortableItem>
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default SortableActionList;
