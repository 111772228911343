import clsx from 'clsx';
import { useGame } from '../../store/game';
import { useMessages } from '../../store/messages';

export const IncludeActionInPlan: React.FC<{ actionId: string }> = props => {
  const includeActionInPlan = useGame(s => s.includeActionInPlan);
  const t = useMessages(({ print }) => print);
  const currentPlan = useGame(s => s.currentPlan);
  const isActionInPlan = useGame(s =>
    s.isActionInPlan(currentPlan, props.actionId)
  );

  return (
    <button
      className={clsx(
        'mx-1 rounded p-2 text-sm transition-all duration-200 ease-in-out leading-tight font-bold',
        isActionInPlan
          ? 'bg-gray-200 cursor-not-allowed text-gray-400'
          : 'bg-white cursor-pointer hover:bg-green-200'
      )}
      disabled={isActionInPlan}
      onClick={() => includeActionInPlan(currentPlan, props.actionId)}
    >
      {t(`include-action-in-${currentPlan}-plan`)}
    </button>
  );
};
