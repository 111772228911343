import { CSSProperties } from 'react';

interface IStylingFn {
  (set: (style: Partial<CSSProperties>) => void): void;
}

export const styler = (...fns: IStylingFn[]): CSSProperties => {
  const props: CSSProperties = {};
  const set = (p: Partial<CSSProperties>) => {
    Object.assign(props, p);
  };

  for (const fn of fns) {
    fn(set);
  }

  return props;
};

export const whileTrue =
  (css: CSSProperties) =>
  (condition: boolean): IStylingFn =>
  set => {
    if (condition) set(css);
  };

export const whileSelected =
  (css: CSSProperties) =>
  (isSelected: boolean): IStylingFn =>
  set => {
    if (isSelected) set(css);
  };

export const highlightWhileSelected =
  (background: string = 'yellow') =>
  (isSelected: boolean): IStylingFn =>
  set => {
    if (isSelected) set({ background });
  };

export const alternateBackground =
  (variants: string[]) =>
  (i: number): IStylingFn =>
  set => {
    set({ background: variants[i % variants.length] });
  };
