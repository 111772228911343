import StringToColor from 'string-to-color';

export default function log(text: string, topic?: string): void {
  if (process.env.NODE_ENV === 'production') return;

  const logTopic = topic || 'Log';

  console.log(
    `%c [${topic || 'Log'}] ${text}`,
    `color: ${
      typeof StringToColor === 'function' ? StringToColor(logTopic) : 'black'
    }`
  );
}
