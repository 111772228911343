import produce from 'immer';
import { StoreSlice } from '../../utils/store-slice';
import { IMessages } from '../concepts/messages';

export const create: StoreSlice<IMessages> = (set, get) => {
  return {
    pastMessages: [],
    cursorOnOriginalConversation: 0,

    send(message) {
      set(
        produce((s: IMessages) => {
          s.pastMessages.push(message);
        })
      );
    },

    advanceConversationCursor() {
      set(
        produce((s: IMessages) => {
          s.cursorOnOriginalConversation = s.cursorOnOriginalConversation + 1;
        })
      );
    },
  };
};
