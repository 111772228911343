import { useMemo } from 'react';
import buildAssetPath from '../../core/buildAssetPath';
import { useGame } from '../../store/game';
import { useMessages } from '../../store/messages';
import { ExtractMultiLines } from '../utils/extract-multi-lines';

const Rules = () => {
  const t = useMessages(({ print }) => print);
  const scenarioName = useGame(({ scenarioName }) => scenarioName);

  const budget: number = useMemo(
    () =>
      scenarioName === 'crisis' ? 150 : scenarioName === 'neutral' ? 120 : 0,
    [scenarioName]
  );

  const planExecutionTime: string = useMemo(
    () =>
      scenarioName === 'crisis'
        ? '1 mese'
        : scenarioName === 'neutral'
        ? '3 mesi'
        : '~',
    [scenarioName]
  );

  return (
    <div className="flex flex-col p-12 space-y-2 overflow-y-scroll font-sans scrollbar-document">
      <h2 className="my-2 text-3xl font-medium leading-none">
        {t('rules-title')}
      </h2>
      <h3 className="my-4 text-xl font-bold">{t('rules-subtitle-1')}</h3>
      <p>{t('rules-intro')}</p>
      <ul>
        <li dangerouslySetInnerHTML={{ __html: t('rules-phase-1') }} />
        <li dangerouslySetInnerHTML={{ __html: t('rules-phase-2') }} />
      </ul>
      <p
        dangerouslySetInnerHTML={{ __html: t('rules-phases-desc', { budget }) }}
      />

      <h3 className="my-4 text-xl font-bold">{t('rules-phase-1-title')}</h3>
      {ExtractMultiLines(t('rules-phase-1-content'))}

      <h3 className="my-4 text-xl font-bold">{t('rules-phase-2-title')}</h3>
      <p>{t('rules-phase-2-intro')}</p>
      <ol className="ml-4">
        <li>{t('rules-phase-2-bullet-1')}</li>
        <li>{t('rules-phase-2-bullet-2')}</li>
      </ol>
      <p>{t('rules-phase-2-content', { planExecutionTime })}</p>

      <h3 className="my-4 text-xl font-bold">{t('rules-subtitle-2')}</h3>

      {ExtractMultiLines(t('rules-nudge-intro'))}
      <ol className="flex flex-col">
        <li>
          <p className="flex flex-row mt-6 mb-1 space-x-2">
            <img
              src={buildAssetPath('images/scan.png')}
              alt="Scan"
              className="w-6 h-6"
            />
            <b>{t('rules-nudge-scanner-intro')}</b>
          </p>
          {ExtractMultiLines(t('rules-nudge-scanner-desc'))}
        </li>

        <li>
          <p className="flex flex-row mt-6 mb-1 space-x-2">
            <img
              src={buildAssetPath('images/laser-pen.png')}
              alt="Laser"
              className="w-6 h-6"
            />
            <b>{t('rules-nudge-laser-intro')}</b>
          </p>
          {ExtractMultiLines(t('rules-nudge-laser-desc'))}
        </li>
      </ol>

      <p className="mt-4">{t('rules-end')}</p>
    </div>
  );
};

export default Rules;
