import produce from 'immer';
import { StoreSlice } from '../../utils/store-slice';
import { IBudget } from '../concepts/budget';

interface IPrivate {}

export const create: StoreSlice<IBudget & IPrivate> = (set, get) => {
  return {
    initialBudget: 100,
    budgetSpent: 0,

    bearCost(cost) {
      set(
        produce((s: IBudget) => {
          s.budgetSpent += cost;
        })
      );
    },

    availableBudget() {
      return get().initialBudget - get().budgetSpent;
    },

    availableBudgetForLearning() {
      return get().initialBudget * 0.2;
    },
  };
};
