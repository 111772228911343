import create, { GetState, SetState } from 'zustand';
import * as Logger from './factories/logger';
import * as Observations from './factories/observations';
import { observations } from './remote/firestore';
import { db } from '../../config.firestore';

const createRootSlice = (set: SetState<any>, get: GetState<any>) => ({
  ...Logger.create(set, get),
  ...Observations.create(observations(db))(set, get),
});

export const useObservations = create(createRootSlice);

// @ts-ignore
window.observations = useObservations;
