import buildAssetPath from '../../core/buildAssetPath';
import { useGame } from '../../store/game';
import { useMessages } from '../../store/messages';
import { SideBySide } from '../layout/side-by-side';
import { ActionPlanningInfo } from './action-planning-info';
import { ExcludeActionFromPlan } from './action.exclude-from-plan-button';
import { IncludeActionInPlan } from './action.include-in-plan-button';

export const ActionSummary: React.FC<{
  actionId: string;
  observerMode?: boolean; //TODO: @deprecated
}> = props => {
  const isPlanSubmitted: (planId: string) => boolean = useGame(
    ({ isPlanSubmitted }) => isPlanSubmitted
  );
  const title: string = useMessages(({ print }) =>
    print(`action-${props.actionId}-name`)
  );
  const description: string = useMessages(({ print }) =>
    print(`action-${props.actionId}-description`)
  );

  const isObserver = useGame(s => s.isObserver);

  return (
    <div>
      <div className="flex flex-row space-x-4 mb-3 items-center">
        <img
          src={buildAssetPath('images/info.png')}
          alt="Info"
          className="h-2 xl:h-3 3xl:h-4"
        />
        <h5 className="font-bold text-base 2xl:text-lg 3xl:text-xl leading-none mt-0">
          {' '}
          {title}
        </h5>
      </div>

      <p className="text-xs 3xl:text-sm leading-tight">{description}</p>

      {!isObserver && <ActionPlanningInfo actionId={props.actionId} />}

      {!isPlanSubmitted('executive') && !isObserver && (
        <SideBySide proportions="1-1">
          <IncludeActionInPlan actionId={props.actionId} />
          <ExcludeActionFromPlan actionId={props.actionId} />
        </SideBySide>
      )}
    </div>
  );
};
