import { PlanEvaluation } from '../../../mechanics/concepts/plan-evaluation';
import { IObservations } from '../../observations/concepts/observations';

export interface IPlan {
  scenario: OrganizationScenario;
  plans: Plan[];
  currentPlan: string;

  includeActionInPlan(planId: string, action: string, after?: number): void;
  moveActionInPlan(planId: string, action: string, after: number): void;
  replaceActionsInPlan(planId: string, actions: string[]): void;
  excludeActionFromPlan(planId: string, action: string): void;
  moveActionInPlanAfterAction(
    planId: string,
    actionId: string,
    targetActionId: string
  ): void;

  searchPlannableActions(planId: string, pattern?: string): string[];

  actionsInPortfolio(): string[];
  actionsInPlan(planId: string): string[];
  actionsNotInPlan(planId: string): string[];
  actionPosition(planId: string, action: string): number;
  isActionInPlan(planId: string, action: string): boolean;
  plansWithAction(action: string): string[];
  planTotalCost(planId: string): number;
  planResidualBudget(planId: string): number;
  isPlanFeasible(planId: string): boolean;

  acceptanceRate(planId: string): number;

  planEvaluation(planId: string): PlanEvaluation;

  // TODO check this
  submitDraftPlan(): void;
  submitExecutionPlan(): void;
  resetExecutionPlan(): void;
  isPlanSubmitted(planId: string): boolean;
  isPlanVisible(planId: string): boolean;
}

export type Plan = {
  id: string;
  actions: string[];
  pickedActions: string[];
  isSubmitted: boolean;
};

export type ActionDescription = {
  id: string;
  name: string;
  description: string;
};

export type ActionScoring = {
  id: string;
  cost: number;
  baseScore: number;
};

export type ActionStrategicFitClassification = {
  id: string;
  strategicFit: StrategicFits;
};

export type ActionKotterClassification = {
  id: string;
  kotterSteps: KotterSteps;
};

export type InferredPlan = {
  actions: string[];
  maxStepsCount: number;
  stages: [
    KotterActionCluster[],
    number[],
    KotterStepCluster[],
    number[][],
    KotterStepPerformance[]
  ];
  acceptanceRate: number;
  strategicMisfit: number;
  entropy: number;
  dispersion: number;
  incompleteness: number;
};

export type KotterActionCluster = {
  inferredStep: KotterSteps;
  actions: Array<{ id: string; kotterStep: KotterSteps }>;
};

export type KotterStepCluster = {
  step: KotterSteps;
  clusters: Array<{ weight: number; distance: number }>;
};

export type KotterStepPerformance = {
  step: KotterSteps;
  performance: number;
};

export type PhaseScore = {
  phaseId: Phases;
  achievedScore: number;
  targetScore: number;
  performanceRate: number;
  westRate: number;
};

export type KotterStepScore = {
  stepId: KotterSteps;
  potentialScore: number;
  achievedScore: number;
  performanceRate: number;
};

export type ActionScore = {
  actionId: string;
  assignedPhase: Phases;

  baseScore: number;
  phaseMisfitPenalty: number;
  kotterStepMisfitPenalty: number;
  strategyMisfitPenalty: number;
  achievedScore: number;
};

export type ActionPenalties = {
  actionId: string;
  assignedPhase: Phases;
  baseScore: number;
  phaseMisfitPenalty: number;
  kotterStepMisfitPenalty: number;
  strategyMisfitPenalty: number;
};

export type ActionCluster = {
  phase: Phases;
  weight: number;
  start: number;
  end: number;
};

export type PhaseRange = {
  phase: Phases;
  start: number;
  end: number;
};

export type IssuedPlan = {
  plannedActions: PlannedAction[];
};

export type PlannedAction = {
  actionId: string;
  baseScore: number;
  assignedPhase: Phases;
  assignedKotterStep: KotterSteps;
  properPhase: Phases;
  properKotterStep: KotterSteps;
  strategyFit: StrategicFits;
};

export type Phases = '--null--' | 'preparation' | 'communication' | 'execution';
export type KotterSteps =
  | '--null--'
  | 'urgency'
  | 'coalition'
  | 'vision-and-strategy'
  | 'communicate'
  | 'empower-action'
  | 'short-term-wins'
  | 'consolidate-gains'
  | 'anchor-change';

export type StrategicFits = 'top-down' | 'bottom-up' | 'neutral';

export type Strategy = 'top-down' | 'bottom-up';
export type ScenarioNames = 'neutral' | 'crisis';

export type OrganizationScenario = {
  strategyToAdopt: Strategy;
  phaseWeights: PhaseWeight[];
};

export type PhaseWeight = {
  phaseId: Phases;
  targetScore: number;
};

export const createOrganizationScenario = (
  scenarioName: ScenarioNames
): OrganizationScenario => {
  if (scenarioName === 'neutral') {
    return {
      strategyToAdopt: 'bottom-up',
      phaseWeights: [
        { phaseId: 'preparation', targetScore: 0 },
        { phaseId: 'communication', targetScore: 0 },
        { phaseId: 'execution', targetScore: 0 },
      ],
    };
  }
  return {
    strategyToAdopt: 'top-down',
    phaseWeights: [
      { phaseId: 'preparation', targetScore: 0 },
      { phaseId: 'communication', targetScore: 0 },
      { phaseId: 'execution', targetScore: 0 },
    ],
  };
};

export const SequenceOfPhases = {
  phases(): Phases[] {
    return ['preparation', 'communication', 'execution'];
  },
};

export const SequenceOfKotterSteps = {
  steps(): KotterSteps[] {
    return [
      'urgency',
      'coalition',
      'vision-and-strategy',
      'communicate',
      'empower-action',
      'short-term-wins',
      'consolidate-gains',
      'anchor-change',
    ];
  },

  positionOf(step: KotterSteps): number {
    return SequenceOfKotterSteps.steps().indexOf(step);
  },

  compare(a: KotterSteps, b: KotterSteps): number {
    return (
      SequenceOfKotterSteps.positionOf(a) - SequenceOfKotterSteps.positionOf(b)
    );
  },
};
