export interface IPredicateFn<T> {
  (v: T, i: number, vs: T[]): boolean;
}

export const ifEqual = (v: any) => (target: any) => target === v;
export const ifGreaterThan = (v: number) => (target: number) => target < v;
export const isEqualTo =
  <T, V extends T>(v: V) =>
  (target: T) =>
    target === v;

export const ifNotEqual = (v: any) => (target: any) => target !== v;

export const notIn =
  <T>(collection: T[]) =>
  (target: T) =>
    !collection.includes(target);

export const ifNotIn = notIn;

export const ifIn =
  <T>(collection: T[]) =>
  (target: T) =>
    collection.includes(target);

export const includes =
  <T>(v: T) =>
  (target: T[]) =>
    target.includes(v);

export const ifValueOf =
  <T, K extends keyof T>(key: K, predicate: (v: T[K]) => boolean) =>
  (v: T) =>
    predicate(v[key]);

export const ifIndexIsBetween =
  (range: [number, number]) => (v: any, i: number) =>
    range[0] <= i && range[1] >= i;

export const ifBoth =
  <T>(a: IPredicateFn<T>, b: IPredicateFn<T>) =>
  (v: T, i: number, vs: T[]) =>
    a(v, i, vs) && b(v, i, vs);
