export const StarIcon: React.FC<{
  onClick: () => void;
  selected: boolean;
}> = props => {
  return (
    <span
      className="w-6 h-6 2xl:w-7 2xl:h-7 3xl:w-8 3xl:h-8 text-xl"
      onClick={props.onClick}
    >
      {props.selected ? '★' : '☆'}
    </span>
  );
};
