import { useEffect, useState } from 'react';

export default function useScaleFactor() {
  const [zoomData, setZoomData] = useState({
    zoom: 1,
    devicePxPerCssPx: 1,
  });

  const applyZoom = () => {
    const zoom: number =
      Math.round((window.outerWidth / window.innerWidth) * 100) / 100;

    setZoomData({
      zoom: zoom,
      devicePxPerCssPx: zoom * window.devicePixelRatio,
    });
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;

    window.addEventListener('resize', applyZoom);

    applyZoom();
  }, []);

  return 1 / (zoomData.devicePxPerCssPx / zoomData.zoom);
}
