export const toAverage = (avg: number, v: number, i: number, vs: number[]) =>
  avg + v / vs.length;
export const toSum = (s: number, a: number) => s + a;

export const toMaxBy =
  <T, K extends keyof T>(key: K) =>
  (max: T, v: T) =>
    max[key] < v[key] ? v : max;

export const toUniq = <T>(collection: T[], v: T) => {
  if (collection.includes(v)) return collection;
  return [...collection, v];
};
