import { ReactNode } from 'react';
import buildOptimizedAssetPath from '../../core/buildOptimizedAssetPath';

interface IProps {
  children: ReactNode | JSX.Element | JSX.Element[];
}

const PhoneFrame = ({ children }: IProps) => (
  <div className="font-sans select-none device device-iphone-x transform-gpu -translate-x-8 xl:translate-x-1 2xl:translate-x-4 xl:translate-y-2 2xl:translate-y-4 shadow-xl">
    <div className="device-frame overflow-hidden h-full">
      <div className="w-full h-full relative rounded-3xl">
        <div
          className="absolute w-full h-full left-0 top-0 opacity-40 mix-blend-screen bg-cover z-10 pointer-events-none"
          style={{
            backgroundImage: `url('${buildOptimizedAssetPath(
              'images/phone-glass-1',
              ['avif', 'jpg']
            )}'`,
          }}
        />
        <div
          className="absolute w-full h-full left-0 overflow-hidden filter top-0 opacity-50 mix-blend-screen bg-cover z-10 pointer-events-none"
          style={{
            backgroundImage: `url('${buildOptimizedAssetPath(
              'images/phone_glass',
              ['avif', 'webp']
            )}'`,
          }}
        />

        <div className="relative flex flex-col justify-start w-full h-full px-2 pt-10 overflow-y-hidden device-content">
          {children}
        </div>
      </div>
    </div>

    <div className="device-stripe" />
    <div className="device-header" />
    <div className="device-sensors" />
    <div className="device-btns" />
    <div className="device-power" />
  </div>
);

export default PhoneFrame;
