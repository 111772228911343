import { IResourceDirectoryApi } from './api';

import { FluentResource } from '@fluent/bundle';

export const useFirestoreDirectory = (): IResourceDirectoryApi => ({
  async read(id) {
    const res = await fetch(id);
    if (!res.ok) throw new Error(res.statusText);

    return new FluentResource(await res.text());
  },
});
