import { useGame } from '../../store/game';
import { units } from '../layout/formatters';
import buildOptimizedAssetPath from '../../core/buildOptimizedAssetPath';
import { CoinIcon } from '../game/coins-icon';
import { useMessages } from '../../store/messages';

const WatchFrame = () => {
  const t = useMessages(({ print }) => print);

  const { planTotalCost, availableBudget, isPlanFeasible } = useGame(
    ({ availableBudget, planTotalCost, currentPlan, isPlanFeasible }) => ({
      planTotalCost: planTotalCost(currentPlan),
      availableBudget: availableBudget(),
      isPlanFeasible: isPlanFeasible(currentPlan),
    })
  );

  return (
    <div className="absolute z-20 h-32 font-sans text-center bottom-2 2xl:bottom-3 3xl:bottom-4 right-6 2xl:right-8 3xl:right-10 lg:h-40 xl:h-48 2xl:h-52 3xl:h-60">
      <div className="relative device device-apple-watch">
        <span className="absolute z-10 block h-4 bg-white w-28 3xl:w-32 top-8 left-7 blur opacity-10" />
        <div
          className="relative flex overflow-hidden device-frame"
          style={{
            background: `linear-gradient(to top, ${
              isPlanFeasible ? 'black' : 'red'
            }, #1f1f1f)`,
          }}
        >
          <div
            className="absolute top-0 left-0 z-10 w-full h-full bg-cover pointer-events-none opacity-20 mix-blend-screen"
            style={{
              backgroundImage: `url('${buildOptimizedAssetPath(
                'images/watch-glass-1',
                ['avif', 'jpg']
              )}')`,
            }}
          />

          <div className="absolute top-6 left-4 filter w-5/6 h-24 z-20 transform-gpu rounded-l-xl rounded-tr-xl rounded-br-[90%] opacity-[0.06] pointer-events-none bg-gradient-to-t from-white to-transparent" />

          <div className="m-auto">
            <p className="leading-none text-gray-100 text-xxs xl:text-xs 2xl:text-sm 3xl:text-base opacity-80">
              {t('watch-available-budget')}
            </p>
            <p className="flex flex-row items-center justify-center mt-1 space-x-2 text-sm font-bold leading-none text-gray-100 2xl:mt-2 3xl:mt-3 2xl:text-base 3xl:text-lg opacity-90">
              <span>{units(availableBudget - planTotalCost)}</span>
              <CoinIcon />
            </p>

            <p className="mt-4 text-gray-100 text-xxs xl:text-xs 2xl:text-sm 3xl:text-base opacity-80 2xl:mt-6 3xl:mt-8">
              {t('watch-plan-cost')}
            </p>
            <p className="flex flex-row items-center justify-center my-1 space-x-2 font-bold leading-none text-gray-100 2xl:my-2 3xl:my-3 text-xxs xl:text-xs 2xl:text-sm 3xl:text-base opacity-90">
              <span>{units(planTotalCost)}</span>
              <CoinIcon />
            </p>
          </div>
        </div>
        <div className="device-stripe" />
        <div className="device-header" />
        <div className="device-sensors" />
        <div className="device-btns" />
        <div className="device-power" />
      </div>
    </div>
  );
};

export default WatchFrame;
