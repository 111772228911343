import { ifValueOf, isEqualTo, isIn, to } from '../../libs/say-it';
import { actions } from '../../mechanics/data/actions';
import {
  IIdealPlan,
  KotterSteps,
  ScenarioNames,
  StrategicFits,
} from '../concepts';

export abstract class IdealPlan {
  static fromScenario(scenario: ScenarioNames): IIdealPlan {
    const strategicFits = scenarioToStrategicFit(scenario);
    const disturbingFits = scenarioToDisturbingFit(scenario);

    return {
      actions: () =>
        actions
          .filter(ifValueOf('strategicFit', isIn(strategicFits)))
          .map(to('id')),

      disturbingActions: () =>
        actions
          .filter(ifValueOf('strategicFit', isIn(disturbingFits)))
          .map(to('id')),

      peouActions: () =>
        actions
          .filter(ifValueOf('strategicFit', isIn(strategicFits)))
          .filter(ifValueOf('isPEOU', isEqualTo(true)))
          .map(to('id')),

      puActions: () =>
        actions
          .filter(ifValueOf('strategicFit', isIn(strategicFits)))
          .filter(ifValueOf('isPU', isEqualTo(true)))
          .map(to('id')),

      toAbsDistanceFromStep: () => (stepId, posInPlan) =>
        Math.abs(SequenceOfKotterSteps.positionOf(stepId) - posInPlan),

      toKotterStep: () => id => {
        const action = actions.find(ifValueOf('id', isEqualTo(id)));
        if (action) return action.kotterStep;
        console.log('!!!', id);
        return '--null--';
      },
      actionsByPhase: phase =>
        actions
          .filter(ifValueOf('strategicFit', isIn(strategicFits)))
          .filter(ifValueOf('kotterStep', isIn(kotterStepsByPhase(phase))))
          .map(to('id')),
    };
  }
}

const kotterStepsByPhase = (phase: string): KotterSteps[] => {
  switch (phase) {
    case 'preparation':
      return ['urgency', 'coalition', 'vision-and-strategy'];
    case 'communication':
      return ['communicate'];
    case 'execution':
      return [
        'empower-action',
        'short-term-wins',
        'consolidate-gains',
        'anchor-change',
      ];
  }
};

const scenarioToStrategicFit = (
  scenarioName: ScenarioNames
): StrategicFits[] => {
  switch (scenarioName) {
    case 'crisis':
      return ['top-down', 'neutral'];
    case 'neutral':
    default:
      return ['bottom-up', 'neutral'];
  }
};

const scenarioToDisturbingFit = (
  scenarioName: ScenarioNames
): StrategicFits[] => {
  switch (scenarioName) {
    case 'crisis':
      return ['bottom-up'];
    case 'neutral':
    default:
      return ['top-down'];
  }
};

export const SequenceOfKotterSteps = {
  steps(): KotterSteps[] {
    return [
      'urgency',
      'coalition',
      'vision-and-strategy',
      'communicate',
      'empower-action',
      'short-term-wins',
      'consolidate-gains',
      'anchor-change',
    ];
  },

  positionOf(step: KotterSteps): number {
    return SequenceOfKotterSteps.steps().indexOf(step);
  },

  compare(a: KotterSteps, b: KotterSteps): number {
    return (
      SequenceOfKotterSteps.positionOf(a) - SequenceOfKotterSteps.positionOf(b)
    );
  },
};
