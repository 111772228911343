import create, { GetState, SetState } from 'zustand';
import * as Logger from './factories/logger';
import * as Messages from './factories/messages';
import { useFirestoreDirectory } from './remote/firestore';

const createRootSlice = (set: SetState<any>, get: GetState<any>) => ({
  ...Logger.create(set, get),
  ...Messages.create(useFirestoreDirectory())(set, get),
});

export const useMessages = create(createRootSlice);
