import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useGame } from '../../../store/game';
import { useMessages } from '../../../store/messages';
import OrderNudge from './order-nudge';

const ActionListItem = ({
  planId,
  action,
  position,
  actionsInPlan,
}: {
  planId: string;
  position: number;
  action: string;
  actionsInPlan: string[];
}) => {
  const isPlanSubmitted = useGame(({ isPlanSubmitted }) =>
    isPlanSubmitted(planId)
  );
  const excludeAction = useGame(
    ({ excludeActionFromPlan }) => excludeActionFromPlan
  );
  const isActionExcluded = useGame(s => s.isActionExcluded);
  const getActionName = useMessages(
    ({ print }) =>
      id =>
        print(`action-${id}-name`)
  );
  const actionCode = useGame(({ actionCode }) => actionCode(action));
  const isObserver = useGame(({isObserver}) => isObserver);
  const t = useMessages(({ print }) => print);

  return (
    <motion.div
      className={clsx(
        'flex static flex-row items-center w-full space-x-2 text-sm 3xl:text-base p-[3px]',
        position % 2 !== 0 ? 'bg-gray-200/70' : 'bg-transparent'
      )}
    >
      <b className="w-1/12">{position + 1}°</b>{' '}
      <span
        title={getActionName(action)}
        className={clsx('w-5/12 truncate', {
          'line-through opacity-30': isActionExcluded(action),
        })}
      >
        {`${actionCode} ${getActionName(action)}`}
      </span>
      <div className="flex flex-row justify-end w-4/12 space-x-1">
        {actionsInPlan.slice(position + 1).map(targetActionId => (
          <OrderNudge
            key={targetActionId}
            actionId={action}
            targetActionId={targetActionId}
          />
        ))}
      </div>
      {!isObserver && !isPlanSubmitted && (
        <button
          className="w-2/12 py-1 font-sans text-xs font-medium text-red-800 uppercase transition-all duration-200 ease-in-out rounded bg-red-100/60 hover:bg-red-200/60 hover:duration-75"
          style={{
            float: 'right',
          }}
          onClick={() => excludeAction(planId, action)}
        >
          {t('plan-action-list-remove-item')}
        </button>
      )}
    </motion.div>
  );
};

export default ActionListItem;
