import buildAssetPath from '../../core/buildAssetPath';
import { useGame } from '../../store/game';
import { Nudges } from '../../store/game/concepts/nudges';
import { useMessages } from '../../store/messages';
import { CoinIcon } from '../game/coins-icon';
import { units } from '../layout/formatters';

interface IProps {
  nudgeId: Nudges;
  onStart?(): void;
  onEnd?(): void;
  delay?: number;
}

const NudgeButton = ({ nudgeId, onStart, onEnd, delay }: IProps) => {
  const cost: number = useGame(({ nudgeCost }) => nudgeCost(nudgeId));
  const isAvailable: boolean = useGame(({ isNudgeAvailable }) =>
    isNudgeAvailable(nudgeId)
  );
  const useNudge = useGame(({ useNudge }) => useNudge);
  const t = useMessages(({ print }) => print);

  const title: string = useMessages(({ print }) =>
    print(`nudges-${nudgeId}-title`)
  );

  const tooltip: string = useMessages(({ print }) =>
    print(`nudges-${nudgeId}-tooltip`)
  );

  return (
    <button
      className="relative flex flex-row items-center px-4 py-1 font-sans text-lg font-medium transition-all duration-200 ease-in-out bg-gray-400 rounded cursor-pointer group hover:bg-gray-400 bg-opacity-40 hover:bg-opacity-60 hover:duration-75 disabled:cursor-not-allowed disabled:bg-opacity-100"
      disabled={!isAvailable}
      title=""
      onClick={() => {
        if (isAvailable) {
          onStart && onStart();

          setTimeout(() => {
            useNudge(nudgeId);
            onEnd && onEnd();
          }, delay || 5000);
        }
      }}
    >
      <span className="absolute z-50 hidden px-3 py-3 text-sm leading-tight text-left text-white transition-all duration-200 ease-in-out bg-black rounded shadow-lg group-hover:block top-12">
        {isAvailable ? tooltip : t('budget-not-enough-for-help')}
      </span>
      <img
        src={buildAssetPath(
          `images/${nudgeId === 'laser' ? 'laser-pen' : 'scan'}.png`
        )}
        alt={nudgeId}
        className="w-6 h-6 mr-2"
      />
      <b>
        {t('use-sai')} {title}
      </b>
      <span className="flex flex-row items-center ml-2">
        ({units(cost)}
        <CoinIcon />)
      </span>
    </button>
  );
};

export default NudgeButton;
