import { useEffect, useState } from 'react';

export default function useMousePosition() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handlePosition = (e: any) => {
      setMousePosition({
        x: e.pageX,
        y: e.pageY,
      });
    };

    typeof window !== 'undefined' &&
      window.addEventListener('mousemove', handlePosition);

    return () =>
      typeof window !== 'undefined' &&
      window.removeEventListener('mousemove', handlePosition);
  }, []);

  return mousePosition;
}
