export const SideBySide: React.FC<{
  children: [React.ReactNode, React.ReactNode];
  proportions: Proportions;
}> = props => (
  <div style={{ display: 'flex', flexDirection: 'row' }}>
    {props.children.map((child, i) => (
      <div key={i} style={{ width: `${PROPORTIONS[props.proportions][i]}%` }}>
        {child}
      </div>
    ))}
  </div>
);

type Proportions = '2-1' | '1-1';

const PROPORTIONS: Record<Proportions, [number, number]> = {
  '1-1': [50, 50],
  '2-1': [66, 34],
};
