import dayjs from 'dayjs';
import { useGame } from '../../store/game';
import { useMessages } from '../../store/messages';
import { ExtractMultiLines } from '../utils/extract-multi-lines';

const Minuta = () => {
  const scenarioName = useGame(({ scenarioName }) => scenarioName);
  const t = useMessages(({ print }) => print);

  return (
    <div className="p-6 overflow-y-scroll font-serif lg:p-8 xl:p-10 2xl:p-12 scrollbar-document">
      <h2 className="mb-0 text-lg font-medium leading-none lg:text-xl xl:text-2xl 2xl:text-3xl">
        {t('minuta-title')}
      </h2>
      <h4 className="my-0 text-xl leading-none">
        {
          /** @TODO: questa dovrebbe essere salvata nello stato all'avvio del gioco  */
          dayjs().subtract(3, 'day').format('DD/MM/YYYY')
        }
      </h4>

      <br />

      <div className="flex flex-col w-4/5 mt-2 space-y-4 text-xs xl:mt-3 2xl:mt-4 xl:text-sm 2xl:text-base">
        {scenarioName === 'neutral' && <ScenarioNeutral />}
        {scenarioName === 'crisis' && <ScenarioCrisis />}
      </div>
    </div>
  );
};

export default Minuta;

const ScenarioCrisis = () => {
  const t = useMessages(({ print }) => print);

  return (
    <>
      <p className="font-bold">{t('minuta-crisis-1-title')}</p>
      {ExtractMultiLines(t('minuta-crisis-1-content'))}
      <p className="font-bold">{t('minuta-crisis-2-title')}</p>
      {ExtractMultiLines(t('minuta-crisis-2-content'))}
      <p className="font-bold">{t('minuta-crisis-3-title')}</p>
      {ExtractMultiLines(t('minuta-crisis-3-content'))}
    </>
  );
};

const ScenarioNeutral = () => {
  const t = useMessages(({ print }) => print);

  return (
    <>
      <p className="font-bold">{t('minuta-neutral-1-title')}</p>
      {ExtractMultiLines(t('minuta-neutral-1-content'))}
      <p className="font-bold">{t('minuta-neutral-2-title')}</p>
      {ExtractMultiLines(t('minuta-neutral-2-content'))}
      <p className="font-bold">{t('minuta-neutral-3-title')}</p>
      {ExtractMultiLines(t('minuta-neutral-3-content'))}
    </>
  );
};
