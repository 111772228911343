import { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import Lottie from 'react-lottie-player';
import ActionsPortfolio from './actions-portfolio';
import { Plan } from './plan';
import NudgeButton from './nudge-button';
import Window from './window';
import { useGame } from '../../store/game';
import DraftPlanResponse from './draft-plan-response';
import Minuta from './minuta';
import Rules from './rules';
import Document from './document';
import FinalResults from './final-results';
import { icons } from './documents-data';
import animation from '../../lottie-animations/90810-qr-code-scanning.json';
import { useMessages } from '../../store/messages';

// TODO: Play SFX on document opening.
const Explorer = () => {
  const t = useMessages(({ print }) => print);
  const documents = useGame(({ availableDocuments }) => availableDocuments);
  const currentPlan = useGame(({ currentPlan }) => currentPlan);
  const isObserver = useGame(s => s.isObserver);
  const isPlanSubmitted = useGame(({ isPlanSubmitted }) => isPlanSubmitted);
  const openedFile = useGame(
    ({ currentActiveDocument }) => currentActiveDocument
  );
  const setOpenedFile = useGame(({ openDocument }) => openDocument);
  const closeFile = useGame(({ closeActiveDocument }) => closeActiveDocument);

  const [isScanning, setScanning] = useState<boolean>(false);

  const explorerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        className="relative flex flex-row flex-wrap max-w-full w-full h-full gap-8 lg:gap-12 xl:gap-24 p-10 lg:p-12 xl:p-16 2xl:p-16"
        ref={explorerRef}
      >
        {documents.map((file, fileIndex) => (
          <Document
            key={fileIndex}
            handleOpen={() => setOpenedFile(file)}
            file={file}
          />
        ))}

        {openedFile === 'final-results' && (
          <Window
            icon={icons[openedFile]}
            title={t(`${openedFile}-title`)}
            parentRef={explorerRef}
            width="64%"
            height="86%"
            onClose={closeFile}
          >
            <FinalResults />
          </Window>
        )}

        {openedFile === 'draft-response' && (
          <Window
            icon={icons[openedFile]}
            title={t(`${openedFile}-title`)}
            parentRef={explorerRef}
            width="64%"
            height="86%"
            onClose={closeFile}
          >
            <DraftPlanResponse />
          </Window>
        )}

        {openedFile === 'draft-plan' && (
          <Window
            icon={icons[openedFile]}
            title={t(`${openedFile}-title`)}
            parentRef={explorerRef}
            width="90%"
            height="89%"
            onClose={closeFile}
            toolbarItems={
              !isObserver &&
              currentPlan === 'draft' && (
                <NudgeButton
                  nudgeId="xray"
                  onStart={() => setScanning(true)}
                  onEnd={() => setScanning(false)}
                />
              )
            }
          >
            {isScanning && (
              <motion.div
                className="absolute inset-0 z-50 h-[96%] my-auto mr-64 top-10 pointer-events-none"
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
              >
                <Lottie
                  play
                  animationData={animation}
                  style={{ height: '100%' }}
                />
              </motion.div>
            )}
            <Plan planId="draft" />
          </Window>
        )}

        {openedFile === 'executive-plan' && (
          <Window
            icon={icons[openedFile]}
            title={t(`${openedFile}-title`)}
            parentRef={explorerRef}
            width="90%"
            height="89%"
            onClose={() => closeFile()}
            toolbarItems={
              !isObserver &&
              currentPlan === 'executive' &&
              !isPlanSubmitted('executive') && (
                <NudgeButton
                  nudgeId="xray"
                  onStart={() => setScanning(true)}
                  onEnd={() => setScanning(false)}
                />
              )
            }
          >
            {isScanning && (
              <motion.div
                className="absolute inset-0 z-50 h-[96%] my-auto mr-64 top-10 pointer-events-none"
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
              >
                <Lottie
                  play
                  animationData={animation}
                  style={{ height: '100%' }}
                />
              </motion.div>
            )}
            <Plan planId="executive" />
          </Window>
        )}

        {openedFile === 'actions-portfolio' && (
          <Window
            icon={icons[openedFile]}
            title={t(`${openedFile}-title`)}
            parentRef={explorerRef}
            width="90%"
            height="86%"
            toolbarItems={
              !isObserver &&
              !isPlanSubmitted('executive') && (
                <NudgeButton nudgeId="laser" delay={100} />
              )
            }
            onClose={closeFile}
          >
            <ActionsPortfolio />
          </Window>
        )}

        {openedFile === 'minuta' && (
          <Window
            icon={icons[openedFile]}
            title={t(`${openedFile}-title`)}
            parentRef={explorerRef}
            width="90%"
            height="80%"
            onClose={closeFile}
          >
            <Minuta />
          </Window>
        )}

        {openedFile === 'rules' && (
          <Window
            icon={icons[openedFile]}
            title={t(`${openedFile}-title`)}
            parentRef={explorerRef}
            width="90%"
            height="80%"
            onClose={closeFile}
          >
            <Rules />
          </Window>
        )}
      </div>
    </>
  );
};

export default Explorer;
