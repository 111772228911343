import { useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Message from './message';
import { useGame } from '../../store/game';
import { Documents } from '../../store/game/concepts/documents';
import { useMessages } from '../../store/messages';

const Statuses = {
  Idle: 0,
  Writing: 1,
  WaitingForAnswer: 2,
};

interface IProps {
  messages: {
    text: string;
    type: string;
    attachment?: Documents;
    externalLink?: string;
  }[];
  status: any;
}

const Messages = ({ messages, status }: IProps) => {
  const scrollTargetRef = useRef<HTMLDivElement>(null);

  const releaseDocument = useGame(({ releaseDocument }) => releaseDocument);
  const t = useMessages(({ print }) => print);

  useEffect(() => {
    scrollTargetRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className="flex-grow scrollbar-thin overflow-y-scroll max-h-[calc(100%_-_180px)] pb-16">
      {messages?.map(({ text, type, attachment, externalLink }, index) => (
        <Message
          key={index}
          text={text}
          type={type}
          attachment={attachment}
          externalLink={externalLink}
          onAttachmentDownload={async () => await releaseDocument(attachment)}
        />
      ))}

      <div
        ref={scrollTargetRef}
        className="relative block w-full h-1 bg-red-400 opacity-0"
      />

      <AnimatePresence exitBeforeEnter>
        {status === Statuses.Writing && (
          <motion.div
            className="relative block w-5/6 mr-auto rounded-r-xl rounded-bl-xl"
            initial={{ left: -100, opacity: 0 }}
            animate={{ left: 0, opacity: 1 }}
            exit={{ left: -100, opacity: 0, transition: { duration: 0.15 } }}
            transition={{ duration: 0.24 }}
          >
            <p className="relative block px-4 py-3 mt-1 text-base italic leading-none text-left text-gray-400">
              {t('chat-writing-indicator')}
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Messages;
