import { PointerEvent, ReactNode, useState } from 'react';
import { AnimatePresence, motion, useDragControls } from 'framer-motion';
import buildAssetPath from '../../core/buildAssetPath';

interface IProps {
  toolbarItems?: ReactNode;
  children?: ReactNode;
  onClose(): void;
  width: string;
  height: string;
  parentRef: any;
  title: string;
  icon?: string;
}

const Window = ({
  toolbarItems,
  children,
  onClose,
  width,
  height,
  parentRef,
  title,
  icon,
}: IProps) => {
  const dragControls = useDragControls();

  const startDrag = (event: PointerEvent<HTMLDivElement>) => {
    dragControls.start(event, { snapToCursor: false });
    event.stopPropagation();
  };

  const [isMaximized, setMaximized] = useState<boolean>(false);

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        dragControls={dragControls}
        drag
        dragListener={false}
        dragConstraints={parentRef}
        dragMomentum={false}
        dragSnapToOrigin={false}
        dragElastic={0.2}
        className="absolute inset-0 z-10 flex flex-col m-auto overflow-hidden bg-white border border-gray-200 shadow-xl rounded-lg xl:rounded-xl"
        initial={{ opacity: 0, y: -100, scale: 0.8 }}
        animate={{
          opacity: 1,
          y: 0,
          scale: 1,
          width: isMaximized ? '100%' : width,
          height: isMaximized ? '100%' : height,
        }}
        exit={{ opacity: 0, y: -100, scale: 0.8 }}
        transition={{ duration: 0.4, type: 'spring' }}
      >
        <div
          className="flex flex-row items-center justify-start w-full h-6 xl:h-8 2xl:h-10 px-3 py-2 space-x-1 xl:space-x-2 bg-neutral-200"
          onPointerDown={startDrag}
        >
          <span
            className="w-2 h-2 xl:w-3 xl:h-3 2xl:w-4 2xl:h-4 bg-red-400 hover:bg-red-500 hover:duration-100 duration-500 transition-all ease-in-out rounded-full cursor-pointer"
            onClick={onClose}
          />
          <span
            className="w-2 h-2 xl:w-3 xl:h-3 2xl:w-4 2xl:h-4 bg-yellow-400 hover:bg-yellow-500 hover:duration-75 duration-500 transition-all ease-in-out rounded-full cursor-pointer"
            onClick={onClose}
          />
          <span
            className="w-2 h-2 xl:w-3 xl:h-3 2xl:w-4 2xl:h-4 bg-green-400 hover:bg-green-500 hover:duration-75 duration-500 transition-all ease-in-out rounded-full cursor-pointer"
            onClick={() => setMaximized(draft => !draft)}
          />

          <span className="flex flex-row space-x-2 flex-grow h-full items-center text-xs xl:text-sm opacity-90 pl-4">
            {
              <img
                className="h-6 _nodrag"
                src={buildAssetPath(`images/${icon}`)}
                alt="Icon"
              />
            }{' '}
            <span>{title}</span>
          </span>
        </div>
        {!!toolbarItems && (
          <div className="flex flex-row items-center justify-end w-full h-12 px-3 py-2 space-x-2 border-t border-b border-t-gray-300 border-b-gray-400/20 bg-neutral-100">
            {toolbarItems}
          </div>
        )}

        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default Window;
