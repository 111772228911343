import { useGame } from '../../../store/game';
import { useMessages } from '../../../store/messages';

interface IProps {
  actionId: string;
  targetActionId: string;
}

const OrderNudge = (props: IProps) => {
  const shouldActionBeBefore = useGame(({ shouldActionBeBefore }) =>
    shouldActionBeBefore(props.actionId, props.targetActionId)
  );
  const getActionName = useMessages(
    ({ print }) =>
      id =>
        print(`action-${id}-name`)
  );

  if (shouldActionBeBefore === 'unsure') return null;
  if (shouldActionBeBefore === 'yes')
    return (
      <div className="relative group">
        <span className="absolute z-40 hidden w-48 px-3 py-2 font-sans text-xs text-green-100 rounded shadow-lg bg-black/60 backdrop-blur-sm group-hover:block right-12">
          Eseguire «{getActionName(props.actionId)}» prima di «
          {getActionName(props.targetActionId)}» sembra una buona idea!
        </span>
        <span className="flex items-center justify-center w-4 h-4 p-1 text-xs font-bold text-white bg-green-500 rounded-full xl:w-6 xl:h-6 cursor-help">
          ✓
        </span>
      </div>
    );

  return (
    <div className="relative group">
      <span className="absolute z-40 block w-56 px-3 py-2 font-sans text-xs leading-tight text-red-100 transition-all duration-200 ease-in-out border-b border-black rounded shadow-lg opacity-0 3xl:text-sm 3xl:leading-tight bg-black/80 backdrop-blur-lg group-hover:opacity-100 right-10">
        Eseguire «{getActionName(props.actionId)}» prima di «
        {getActionName(props.targetActionId)}» potrebbe essere
        controproducente...
      </span>
      <span className="relative flex items-center justify-center w-4 h-4 p-1 font-bold text-white bg-red-500 rounded-full xl:w-6 xl:h-6 cursor-help">
        !
      </span>
    </div>
  );
};

export default OrderNudge;
