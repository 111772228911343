import useQueryParam from '../../hooks/useQueryParam.hook';
import useEvent from '@react-hook/event';
import qs from 'query-string';
import { useGame } from '../../store/game';
import { useObservations } from '../../store/observations';
import { useEffect, useState } from 'react';
import { useMessages } from '../../store/messages';

export const RegisterObservationsHelper = () => {
  const t = useMessages(({ print }) => print);
  const observations = useObservations();
  const registerObservations = useGame(
    ({ registerObservations }) => registerObservations
  );

  const forceRegisterObservations: string = useQueryParam(
    'forceRegisterObservations'
  );

  const [stateKey, setStateKey] = useState(null);

  useEffect(() => {
    const parsed = qs.parse(location.search);

    const gameId: string = parsed?.g?.toString();
    const playerId: string = parsed?.p?.toString();
    setStateKey(`change-u-${gameId}:${playerId}`);
  });

  useEvent(window, 'keypress', ({ code }) => {
    if (!forceRegisterObservations) return;

    if (code === 'KeyR')
      registerObservations(observations)
        .then(() => console.log('observations registered'))
        .catch(console.error);
  });

  return (
    <a
      className="px-8 py-1 mt-1 text-xs font-bold text-white transition-all duration-200 ease-in-out bg-blue-500 rounded-full xl:mt-2 2xl:mt-4 3xl:mt-8 hover:bg-blue-600 hover:duration-75 2xl:py-2 xl:text-sm 2xl:text-base 3xl:text-lg"
      href={`data:text/plain;charset=utf-8,${encodeURIComponent(
        localStorage.getItem(stateKey)
      )}`}
      download={stateKey}
      onClick={() => {
        registerObservations(observations)
          .then(() => console.log(t('debug-observations-saved')))
          .catch(error => {
            console.error(
              `${t('debug-observations-save-error')}: ${error.message}`
            );
          });
      }}
    >
      {t('download-session-data')}
    </a>
  );
};
