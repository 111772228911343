import { useEffect } from 'react';
import { useMessages } from '../../store/messages';

export const MessagesInitializer = () => {
  const init = useMessages(s => s.init);

  useEffect(() => {
    init({
      it: process.env.MESSAGES_URL_IT,
      en: process.env.MESSAGES_URL_EN,
    });
  }, []);

  return null;
};
