import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useMessages } from '../../store/messages';

interface IProps {
  options: { text: string; attachment?: string }[];
  answer?: { text: string; attachment?: string };
  handleMessageSelection: (message: {
    text: string;
    attachment?: string;
  }) => void;
}

const AnswerPicker = ({ options, answer, handleMessageSelection }: IProps) => {
  const t = useMessages(({ print }) => print);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const answerLabel: string = options?.some(
    ({ text }) => text === 'chat-answer-6'
  )
    ? 'chat-answer-label-send-draft'
    : options?.some(
        ({ text }) => text === 'chat-answer-10' /*Si, ecco il piano!*/
      )
    ? 'chat-answer-label-send-executive'
    : 'chat-answer-label-reply';

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="relative m-auto text-center transform-gpu"
      >
        {answer ? (
          t(answer.text)
        ) : (
          <>
            <p className="uppercase">{t(answerLabel)}</p>
            <p className="absolute top-0 uppercase animate-ping">
              {t(answerLabel)}
            </p>
          </>
        )}
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          onClose={() => setIsOpen(false)}
          className="relative z-50 select-none"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="inset-0 overflow-y-auto font-sans fixe">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300 transform-gpu"
                enterFrom="opacity-0 h-1 rotate-4 translate-y-4"
                enterTo="opacity-100 h-auto rotate-0 translate-y-0"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed flex items-center justify-center p-4 left-10 bottom-20">
                  <Dialog.Panel className="w-full max-w-sm p-8 bg-white shadow-lg rounded-xl">
                    {options?.map(answer => (
                      <div
                        key={answer.text}
                        className="p-4 mb-2 transition-all rounded-lg cursor-pointer bg-blue-100/70 transform-gpu hover:scale-105 hover:-translate-y-px hover:text-blue-900 hover:bg-blue-100/90"
                        onClick={() => {
                          handleMessageSelection(answer);
                          setIsOpen(false);
                        }}
                      >
                        {t(answer.text)}
                      </div>
                    ))}
                  </Dialog.Panel>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AnswerPicker;
