export default function seededShuffle<T>(array: T[], seed: number): T[] {
  function _random(seed) {
    var x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  }

  let m = array.length,
    t,
    i;

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(_random(seed) * m--);

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
    ++seed;
  }

  return array;
}
