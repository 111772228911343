import { motion } from 'framer-motion';
import ComputerFrame from '../computer/computer-frame';
import useMousePosition from '../../hooks/useMousePosition.hook';
import buildOptimizedAssetPath from '../../core/buildOptimizedAssetPath';
import useScaleFactor from '../../hooks/useScaleFactor.hook';
import BrandedHeader from './branded-header';
import buildAssetPath from '../../core/buildAssetPath';
import { RegisterObservationsHelper } from '../game/register-observations-helper';
import { useMessages } from '../../store/messages';

interface IProps {
  handleGameStart: () => void;
}

const LandingPage = ({ handleGameStart }: IProps) => {
  const t = useMessages(({ print }) => print);
  const { x: mouseX, y: mouseY } = useMousePosition();
  const scaleFactor: number = useScaleFactor();

  return (
    <>
      <div className="flex flex-col items-center justify-center w-screen h-screen p-8 text-4xl lg:hidden">
        <p>Please use a higher resolution display to play this game.</p>
      </div>

      <div className="flex-col hidden w-screen h-screen overflow-hidden md:flex">
        <BrandedHeader scaleFactor={scaleFactor} variant="internal" />

        <motion.div
          animate={{
            x: mouseX ? -mouseX / 450 : 0,
            y: mouseY ? -mouseY / 450 : 0,
            scale: 1.02,
          }}
          transition={{
            duration: 0.2,
            ease: 'linear',
          }}
          className="absolute bottom-0 w-full h-full overflow-hidden bg-bottom bg-cover"
          style={{
            backgroundImage: `url('${buildOptimizedAssetPath('images/bg_room', [
              'avif',
              'webp',
            ])}')`,
            boxShadow:
              'inset 0 0 20px 20px rgb(0 0 0 / 50%), inset 0 0 4px 4px rgb(0 0 0 / 90%)',
          }}
        />

        <motion.div
          animate={{
            x: mouseX ? mouseX / 200 : 0,
            y: mouseY ? mouseY / 200 : 0,
            scale: 1.06,
          }}
          transition={{
            duration: 0.4,
            ease: 'linear',
          }}
          className="absolute bottom-0 w-full h-full overflow-hidden bg-cover mix-blend-overlay opacity-40"
          style={{
            backgroundImage: `url('${buildOptimizedAssetPath(
              'images/bg_lights',
              ['avif', 'webp']
            )}')`,
            boxShadow:
              'inset 0 0 20px 20px rgb(0 0 0 / 50%), inset 0 0 4px 4px rgb(0 0 0 / 90%)',
          }}
        />

        <div
          className="w-full h-[120px] 4xl:h-[140px] overflow-hidden absolute bottom-0 bg-cover bg-bottom"
          style={{
            backgroundImage: `url('${buildOptimizedAssetPath('images/table', [
              'avif',
              'jpg',
            ])}')`,
          }}
        />
        <div className="relative flex flex-row flex-grow w-full gap-4 py-4 bg-none xl:py-5 2xl:py-10 overflow-clip">
          <div className="relative w-5/6 h-full mx-auto -translate-y-8 lg:w-4/5 xl:w-2/3 2xl:w-2/3 2xl:p-8 transform-gpu">
            <ComputerFrame>
              <div className="relative flex flex-col items-center justify-center w-full h-full overflow-hidden bg-transparent rounded backdrop-blur-lg">
                <div className="relative block w-20 h-20 bg-white rounded-full xl:w-24 2xl:w-28 3xl:w-32 xl:h-24 2xl:h-28 3xl:h-32" />
                <p className="mt-2 text-base font-bold text-white xl:mt-3 2xl:mt-4 3xl:mt-6 2xl:text-lg 3xl:text-xl">
                  {t('welcome')}
                </p>
                <p className="text-white text-xxs 2xl:text-xs 3xl:text-sm">
                  {t('start-button-desc')}
                </p>
                <button
                  className="px-8 py-2 mt-2 text-sm font-bold text-white transition-all duration-200 ease-in-out bg-blue-500 rounded-full xl:mt-3 2xl:mt-4 3xl:mt-8 hover:bg-blue-600 hover:duration-75 2xl:text-base 3xl:text-lg"
                  onClick={handleGameStart}
                >
                  {t('start')}
                </button>

                <RegisterObservationsHelper />

                <span className="absolute flex flex-row items-center justify-center w-full space-x-10 bottom-4 2xl:bottom-6 3xl:bottom-8">
                  {(
                    <div className="flex flex-col mt-auto mb-1 space-y-1 text-center text-white xl:space-y-2 2xl:space-y-3 3xl:space-y-4">
                      <p className="text-xs font-semibold">Faculty author:</p>
                      <p className="text-lg">Leonardo Caporarello</p>
                    </div>
                  ) ||
                    ['freeze', 'restart', 'shutdown'].map(action => (
                      <span
                        key={action}
                        className="flex flex-col items-center justify-center p-2 space-y-2 transition-all duration-200 ease-in-out rounded-lg cursor-pointer opacity-90 bg-white/0 backdrop-blur-lg hover:bg-white/20"
                      >
                        <img
                          src={buildAssetPath(`images/${action}.svg`)}
                          alt={action}
                          className="w-6 h-6 invert _nodrag"
                        />
                        <p className="text-xs font-light text-center text-white">
                          {action}
                        </p>
                      </span>
                    ))}
                </span>
              </div>
            </ComputerFrame>
          </div>

          <div className="flex w-1/3 h-full mt-auto drop-shadow-lg">
            <img
              src={buildOptimizedAssetPath('images/plant1', ['avif', 'png'])}
              alt="Giardicus selvaticus"
              title="Giardicus selvaticus"
              className="4xl:translate-y-6"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
