import { useGame } from '../../../store/game';
import { useMessages } from '../../../store/messages';
import { CoinIcon } from '../../game/coins-icon';
import { units } from '../../layout/formatters';

const CostsSummary: React.FC<{ planId: string }> = props => {
  const initialBudget = useGame(({ initialBudget }) => initialBudget);
  const budgetSpent = useGame(({ budgetSpent }) => budgetSpent);
  const availableBudgetForLearning = useGame(({ availableBudgetForLearning }) =>
    availableBudgetForLearning()
  );
  const availableBudget = useGame(({ availableBudget }) => availableBudget());
  const planTotalCost = useGame(({ planTotalCost }) =>
    planTotalCost(props.planId)
  );
  const planResidualBudget = useGame(({ planResidualBudget }) =>
    planResidualBudget(props.planId)
  );
  const t = useMessages(({ print }) => print);

  return (
    <div className="w-full text-xs xl:text-sm 2xl:text-base">
      <p className="my-4 text-lg font-bold xl:my-4 xl:text-xl">
        {t('plan-costs-summary')}
      </p>
      <div className="flex flex-col w-full">
        <div className="flex flex-row justify-between px-2 py-1 bg-gray-200/70">
          <span>{t('plan-initial-budget')}</span>
          <span className="flex flex-row items-center space-x-2">
            <p className="font-semibold text-right text-gray-500">
              {units(initialBudget)}
            </p>
            <CoinIcon />
          </span>
        </div>
        <div className="flex flex-row justify-between px-2 py-1">
          <span>{t('plan-budget-spent-in-sai')}</span>
          <span className="flex flex-row items-center space-x-2">
            <p className="font-semibold text-right text-gray-500">
              {units(budgetSpent) +
                ` ${t('common-of')} ` +
                units(availableBudgetForLearning)}
            </p>
            <CoinIcon />
          </span>
        </div>
        <div className="flex flex-row justify-between px-2 py-1 bg-gray-200/70">
          <span>{t('plan-budget-available')}</span>
          <span className="flex flex-row items-center space-x-2">
            <p className="font-semibold text-right text-gray-500">
              {units(availableBudget)}
            </p>
            <CoinIcon />
          </span>
        </div>
        <div className="flex flex-row justify-between px-2 py-1">
          <span>{t('plan-total-cost')}</span>
          <span className="flex flex-row items-center space-x-2">
            <p className="font-semibold text-right text-gray-500">
              {units(planTotalCost)}
            </p>
            <CoinIcon />
          </span>
        </div>
        <div className="flex flex-row justify-between px-2 py-1 bg-gray-200/70">
          <span>{t('plan-residual-budget')}</span>
          <span className="flex flex-row items-center space-x-2">
            <p className="font-semibold text-right text-gray-500">
              {units(planResidualBudget)}
            </p>
            <CoinIcon />
          </span>
        </div>
      </div>
    </div>
  );
};

export default CostsSummary;
