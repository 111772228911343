import { StoreSlice } from '../../utils/store-slice';
import { INudges, Nudges } from '../concepts/nudges';
import { IBudget } from '../concepts/budget';
import { ILogger } from '../concepts/logger';
import { GetState, SetState } from 'zustand';
import produce from 'immer';
import { IActions } from '../concepts/actions';
import { IPlan, SequenceOfKotterSteps } from '../concepts/plan';
import { identity, to, toObject } from '../../utils/mappers';
import { by } from '../../utils/comparators';
import { toUniq } from '../../../libs/say-it';
import { notIn } from '../../utils/predicates';

interface IPrivate {
  nudgesCost: Record<Nudges, number>;
  excludedActions: string[];
  actionsInPartialOrder: string[];
}

export const create: StoreSlice<INudges & IPrivate, IBudget & ILogger> = (
  set,
  get
) => {
  return {
    nudgesCost: {
      xray: 2,
      laser: 1,
    },

    excludedActions: [],
    actionsInPartialOrder: [],

    isActionExcluded(actionId: string) {
      return get().excludedActions.includes(actionId);
    },

    shouldActionBeBefore(actionId: string, targetActionId: string) {
      if (
        !get().actionsInPartialOrder.includes(actionId) ||
        !get().actionsInPartialOrder.includes(targetActionId)
      )
        return 'unsure';

      if (
        get().actionsInPartialOrder.indexOf(actionId) <
        get().actionsInPartialOrder.indexOf(targetActionId)
      )
        return 'yes';

      return 'no';
    },

    isNudgeAvailable(nudge) {
      return (
        get().availableBudgetForLearning() - get().budgetSpent >=
        get().nudgeCost(nudge)
      );
    },

    nudgeCost(nudge: Nudges) {
      return get().nudgesCost[nudge] || 0;
    },

    useNudge(nudge) {
      if (!get().isNudgeAvailable(nudge)) {
        throw new Error(`Nudge ${nudge} is not available`);
      }

      makeNudgeExecutor(nudge)(get, set)(() => {
        get().bearCost(get().nudgeCost(nudge));
      });
    },
  };
};

interface INudgeExecutor {
  (bearCost: () => void): void;
}

interface INudgeExecutorFactory {
  (get: GetState<any>, set: SetState<any>): INudgeExecutor;
}

const makeNudgeExecutor = (nudge: Nudges): INudgeExecutorFactory => {
  switch (nudge) {
    case 'laser':
      return laserNudge;
    case 'xray':
      return xRayNudge;
    default:
      return nullNudge;
  }
};

const xRayNudge =
  (
    get: GetState<INudges & IPlan & IActions & IPrivate>,
    set: SetState<IPrivate>
  ) =>
  (bearCost: () => void) => {
    const actions = get()
      .actionsInPlan(get().currentPlan)
      .filter(notIn(get().actionsInPartialOrder))
      .map(
        toObject({
          action: identity,
          step: get().actionKotterStep,
        })
      )
      .sort(by('step', SequenceOfKotterSteps.compare))
      .map(to('action'));

    const i = Math.floor(Math.random() * (actions.length - 2));
    const j = i + 1;

    const first = actions[i];
    const second = actions[j];

    bearCost();
    set(
      produce((s: IPrivate) => {
        s.nudgesCost['xray'] *= 2;
        s.actionsInPartialOrder.push(first);
        s.actionsInPartialOrder.push(second);
        s.actionsInPartialOrder = s.actionsInPartialOrder
          .reduce(toUniq(), [])
          .map(
            toObject({
              action: identity,
              step: get().actionKotterStep,
            })
          )
          .sort(by('step', SequenceOfKotterSteps.compare))
          .map(to('action'));
      })
    );
  };

const laserNudge =
  (
    get: GetState<INudges & IActions & IPlan & IPrivate>,
    set: SetState<IPrivate>
  ) =>
  (bearCost: () => void) => {
    const [first] = shuffle(
      get()
        .actionsNotInFitWith(get().scenario)
        .filter(a => !get().excludedActions.includes(a))
    );

    if (first) {
      set(
        produce((s: IPrivate) => {
          s.excludedActions.push(first);
        })
      );
      bearCost();
    }
  };

const nullNudge = (get: GetState<INudges & ILogger>) => () => {
  get().log('null nudge invoked');
};

function shuffle<T>(arr: T[], options?: any) {
  if (!Array.isArray(arr)) {
    throw new Error('shuffle expect an array as parameter.');
  }

  options = options || {};

  var collection = arr,
    len = arr.length,
    rng = options.rng || Math.random,
    random,
    temp;

  if (options.copy === true) {
    collection = arr.slice();
  }

  while (len) {
    random = Math.floor(rng() * len);
    len -= 1;
    temp = collection[len];
    collection[len] = collection[random];
    collection[random] = temp;
  }

  return collection;
}
