import { Conversation } from '../store/game/concepts/messages';

const conversationWithObserver: Conversation = [
  {
    type: 'text',
    text: 'chat-observer-1',
    timeToWrite: 1.5,
    timeToRead: 0.5,
  },
  {
    type: 'text',
    text: 'chat-observer-2',
    timeToWrite: 2.5,
    timeToRead: 1.5,
  },
  {
    type: 'text',
    text: 'chat-observer-3',
    timeToWrite: 4,
    timeToRead: 2,
  },
  {
    type: 'text',
    text: 'chat-observer-4',
    timeToWrite: 4,
    timeToRead: 2,
  },
  {
    type: 'text',
    text: 'chat-observer-5',
    timeToWrite: 4,
    timeToRead: 2,
  },
  {
    type: 'text',
    text: 'chat-observer-6',
    timeToWrite: 4,
    timeToRead: 2,
  },
  {
    type: 'attachment',
    attachment: 'minuta',
  },
  {
    type: 'text',
    text: 'chat-observer-7',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'text',
    text: 'chat-observer-8',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'question',
    questionId: 'minuta-read',
    options: [{ text: 'chat-observer-answer-1' }],
  },
  {
    type: 'text',
    text: 'chat-observer-9',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'attachment',
    attachment: 'rules',
  },
  {
    type: 'text',
    text: 'chat-observer-10',
    timeToWrite: 1,
    timeToRead: 0.5,
  },
  {
    type: 'question',
    questionId: 'instructions-explanation',
    options: [{ text: 'chat-observer-answer-2' }],
  },
  {
    type: 'text',
    text: 'chat-observer-11',
    timeToWrite: 3,
    timeToRead: 2,
  },
  {
    type: 'attachment',
    attachment: 'actions-portfolio',
  },
  {
    type: 'text',
    text: 'chat-observer-12',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'question',
    questionId: 'actions-portfolio-read',
    options: [{ text: 'chat-observer-answer-3' }],
  },
  {
    type: 'text',
    text: 'chat-observer-13',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'text',
    text: 'chat-observer-14',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'text',
    text: 'chat-observer-15',
    timeToWrite: 4,
    timeToRead: 3,
  },
  {
    type: 'text',
    text: 'chat-observer-16',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'text',
    text: 'chat-observer-17',
    timeToWrite: 1,
    timeToRead: 0.5,
  },
  {
    type: 'question',
    questionId: 'plan-explanation',
    options: [{ text: 'chat-observer-answer-4' }],
  },
  {
    type: 'text',
    text: 'chat-observer-18',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'text',
    text: 'chat-observer-19',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'text',
    text: 'chat-observer-20',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'text',
    text: 'chat-observer-21',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'question',
    questionId: 'ready-to-play',
    options: [{ text: 'chat-observer-answer-5' }],
  },
  {
    type: 'text',
    text: 'chat-observer-22',
    timeToWrite: 2,
    timeToRead: 1,
  },
];

export default conversationWithObserver;
