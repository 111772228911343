import clsx from 'clsx';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export default function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: '#dedeff',
    borderRadius: '4px',
    marginBottom: '2px',
    cursor: 'default',
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className={clsx(
        'flex flex-row items-center w-full space-x-2 text-xs xl:text-sm 2xl:text-base p-[3px] relative',
        isDragging && 'shadow-lg z-20'
      )}
    >
      <span
        className="mr-2 rounded bg-gray-500/5"
        {...listeners}
        style={{
          padding: '4px',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'grid',
          gridGap: '2px',
          gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
          gridTemplateRows: 'repeat(4, minmax(0, 1fr))',
          cursor: props.isPlanSubmitted ? 'default' : 'grab',
        }}
      >
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => (
          <span
            key={i}
            className="rounded-full"
            style={{
              backgroundColor: 'rgba(255,255,255,0.9)',
              width: '3px',
              height: '3px',
              display: 'block',
              position: 'relative',
            }}
          />
        ))}
      </span>
      {props.children}
    </div>
  );
}
