import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useGame } from '../../../store/game';
import IncludeActionInput from './include-action-input';
import CostsSummary from './costs-summary';
import ActionListItem from './action-list-item';
import SortableActionList from './sortable-action-list';
import { useMessages } from '../../../store/messages';
import ReactMarkdown from 'react-markdown';

interface IProps {
  planId: string;
}

export const Plan = ({ planId }: IProps) => {
  const t = useMessages(({ print }) => print);
  const isPlanVisible = useGame(({ isPlanVisible }) => isPlanVisible);
  const isObserver = useGame(({ isObserver }) => isObserver);
  const resetExecutionPlan = useGame(
    ({ resetExecutionPlan }) => resetExecutionPlan
  );
  const actionsInPlan = useGame(({ actionsInPlan }) => actionsInPlan(planId));
  const moveActionInPlanAfterAction = useGame(
    ({ moveActionInPlanAfterAction }) => moveActionInPlanAfterAction
  );
  const isPlanFeasible = useGame(({ isPlanFeasible }) =>
    isPlanFeasible(planId)
  );
  const isPlanSubmitted = useGame(({ isPlanSubmitted }) =>
    isPlanSubmitted(planId)
  );

  const [isEditModeOn, setEditModeOn] = useState(false);
  const [resettingTimeout, setResettingTimeout] = useState<NodeJS.Timeout>();

  return (
    <div className="relative p-6 overflow-y-scroll font-sans lg:p-8 xl:p-10 2xl:p-12 scrollbar-document">
      <div className="flex flex-row justify-between w-full">
        <h2 className="relative flex flex-col items-baseline m-0 mb-2 font-serif text-xl font-medium leading-none xl:text-2xl 2xl:text-3xl">
          <span
            className={clsx(
              !isPlanFeasible && 'underline decoration-red-400 decoration-wavy'
            )}
          >
            {t(`${planId}-plan`)}
          </span>
          <span
            className={clsx(
              'mt-3 mb-6 text-decoration-none py-2 px-4 block rounded bg-red-100 border-2 border-red-500 text-xs text-red-400 font-sans',
              !isPlanFeasible ? 'opacity-100' : 'opacity-0'
            )}
          >
            {t('plan-budget-exceeded')}
          </span>
        </h2>
      </div>
      {isPlanVisible(planId) ? (
        <>
          {planId === 'draft' && (
            <>
              <p>
                <ReactMarkdown>{t('plan-draft-description-1')}</ReactMarkdown>
                <br />
                <ReactMarkdown>{t('plan-draft-description-2')}</ReactMarkdown>
              </p>
              <br />
              <p>
                <ReactMarkdown>{t('plan-draft-description-3')}</ReactMarkdown>
              </p>
            </>
          )}
          {planId === 'executive' && (
            <>
              <p>{t('plan-executive-description')}</p>
            </>
          )}

          <CostsSummary planId={planId} />

          {!isPlanSubmitted && (
            <div className="flex flex-row">
              {!isObserver && (
                <button
                  onClick={() => setEditModeOn(!isEditModeOn)}
                  className="flex-none w-2/5 h-10 px-4 py-2 m-auto mt-4 text-xs bg-blue-100 rounded hover:bg-blue-200 xl:text-sm 2xl:text-base"
                >
                  {isEditModeOn ? 'Termina Modifica' : 'Aggiungi Azione'}
                </button>
              )}

              {!isObserver && planId === 'executive' && (
                <button
                  onClick={() => {
                    if (resettingTimeout) {
                      resetExecutionPlan();
                      setResettingTimeout(undefined);
                    } else
                      setResettingTimeout(
                        setTimeout(() => setResettingTimeout(undefined), 3000)
                      );
                  }}
                  className="relative flex-none w-2/5 h-10 px-4 py-2 m-auto mt-4 text-xs bg-orange-100 rounded hover:bg-orange-200 xl:text-sm 2xl:text-base"
                >
                  <motion.span
                    className="rounded"
                    style={{
                      backgroundColor: !resettingTimeout
                        ? 'transparent'
                        : '#ffbb56',
                    }}
                    initial={{
                      width: '100%',
                      height: '4px',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                    }}
                    animate={{ width: !resettingTimeout ? '100%' : '0%' }}
                    transition={{ duration: 3, easing: 'linear' }}
                  />
                  {resettingTimeout
                    ? t('plan-click-to-confirm')
                    : t('plan-start-from-draft')}
                </button>
              )}
            </div>
          )}
          <Placeholder visible={isEditModeOn} showContent>
            <IncludeActionInput planId={planId} after={actionsInPlan.length} />
          </Placeholder>

          <div className="relative">
            <p className="my-4 font-bold">{t('plan-actions-list')}</p>
            {!actionsInPlan.length && (
              <p className="text-sm italic text-center">
                {t('plan-actions-list-empty')}
              </p>
            )}
            {isObserver || isPlanSubmitted ? (
              actionsInPlan.map((action, index) => (
                <ActionListItem
                  key={index}
                  planId={planId}
                  position={index}
                  action={action}
                  actionsInPlan={actionsInPlan}
                />
              ))
            ) : (
              <SortableActionList
                planId={planId}
                actions={actionsInPlan}
                onReorder={event => {
                  moveActionInPlanAfterAction(
                    planId,
                    event.active.id,
                    event.over.id
                  );
                }}
                Item={({ position, action }) => (
                  <ActionListItem
                    planId={planId}
                    position={position}
                    action={action}
                    actionsInPlan={actionsInPlan}
                  />
                )}
                Before={({ position }) => <span />}
                After={({ position }) => <span />}
              />
            )}
          </div>
        </>
      ) : (
        <span>{t('plan-complete-draft-before-executive')}</span>
      )}
    </div>
  );
};

const Placeholder: FC<{
  visible: boolean;
  showContent: boolean;
  children: React.ReactElement;
}> = ({ visible, showContent, children }) => {
  if (showContent && visible) return children;
  else return <span />;
};
