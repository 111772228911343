import buildAssetPath from './buildAssetPath';
import isEdge from './isEdge';
import isSafari from './isSafari';

export default function buildOptimizedAssetPath(
  path: string,
  formats: [optimized: string, unoptimized: string]
): string {
  return buildAssetPath(
    `${path}.${isSafari() || isEdge() ? formats[1] : formats[0]}`
  );
}
