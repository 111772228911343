import buildAssetPath from '../../core/buildAssetPath';
import buildOptimizedAssetPath from '../../core/buildOptimizedAssetPath';
import { useMessages } from '../../store/messages';

interface IProps {
  title: string;
  text: string | React.ReactNode;
}

const CEOEmail = ({ title, text }: IProps) => {
  const t = useMessages(({ print }) => print);

  return (
    <div className="p-12 overflow-y-scroll font-sans scrollbar-document">
      <span className="flex flex-row items-center mb-3 space-x-2">
        <p className="text-sm font-semibold text-gray-900 xl:text-base 2xl:text-lg">
          {title}
        </p>

        <span className="self-center px-2 py-1 text-xs text-gray-500 bg-gray-200 rounded">
          Inbox
        </span>
      </span>

      <span className="flex flex-row justify-between mb-4">
        <span className="flex flex-row space-x-4">
          <span className="w-10 h-10 bg-gray-200 border-2 border-gray-200 rounded-full">
            <img
              src={buildOptimizedAssetPath('images/ceo', ['avif', 'png'])}
              alt="Ceo"
              className="w-full h-full"
            />
          </span>

          <span className="flex flex-col">
            <p className="text-xs font-bold text-gray-900 xl:text-sm">
              Elettra Piramidale
            </p>
            <p className="text-xs font-light text-gray-600 xl:text-sm">to me</p>
          </span>
        </span>

        <span className="flex flex-row items-center space-x-4">
          <img
            src={buildAssetPath('images/star.svg')}
            alt="Star"
            className="w-4 h-4 -translate-y-2 opacity-50 transform-gpu"
          />
          <img
            src={buildAssetPath('images/print.svg')}
            alt="Print"
            className="w-4 h-4 -translate-y-2 opacity-50 transform-gpu"
          />
        </span>
      </span>

      <div className="text-xs text-gray-900 xl:text-sm 2xl:text-base">
        {text}
      </div>
      <p className="mt-4 text-xs text-right text-gray-900 xl:text-sm 2xl:text-base">
        {t('response-signature')}
      </p>
      <p className="text-xs text-right text-gray-900 xl:text-sm 2xl:text-base">
        CEO
      </p>
      <p className="text-xs text-right text-gray-900 xl:text-sm 2xl:text-base">
        Ace S.P.A.
      </p>
    </div>
  );
};

export default CEOEmail;
