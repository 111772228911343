import { useState } from 'react';
import MainLayout from './comps/layout/main-layout';
import LandingPage from './comps/layout/landing';
import { MessagesInitializer } from './comps/messages/initializer';
import { Initializer } from './comps/game/initializer';
import Debugger from './comps/debugger';
import useDiagnostics from './hooks/useDiagnostics.hook';
import { useGame } from './store/game';
import useGameQueryParams from './hooks/useGameQueryParams.hook';
import MissingGameData from './comps/layout/missing-game-data';
import { useMessages } from './store/messages';
import '@reach/tooltip/styles.css';
import './styles/devices.css';
import './styles/globals.css';

export const App = () => {
  const [isGameStarted, setGameStarted] = useState<boolean>(false);
  const isStarted = useGame(({ isStarted }) => isStarted);
  const start = useGame(({ start }) => start);
  const { gameInfoId, demoId } = useGameQueryParams();
  const messages = useMessages(store => store);

  useDiagnostics();

  if (typeof window !== 'undefined')
    if (window.location.href.endsWith('/debug')) return <Debugger />;

  if (!gameInfoId && !demoId) return <MissingGameData />;

  return (
    <Initializer>
      <MessagesInitializer />

      <div className="relative w-screen h-screen overflow-hidden">
        <button
          className="absolute z-[5000] p-2 m-2 bg-gray-200 shadow-xl cursor-pointer rounded-xl top-4 left-4"
          onClick={() =>
            messages.changeIdiom(messages.idiom === 'en' ? 'it' : 'en')
          }
        >
          {messages.idiom}
        </button>

        {!isGameStarted ? (
          <LandingPage
            handleGameStart={() => {
              if (!isStarted) start();
              setGameStarted(true);
            }}
          />
        ) : (
          typeof window !== 'undefined' && <MainLayout />
        )}
      </div>
    </Initializer>
  );
};
