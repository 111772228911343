import { useEffect, useState } from 'react';

const Debugger = () => {
  const [browserInfo, setBrowserInfo] = useState<any>({ loading: 'true' });

  useEffect(() => {
    setBrowserInfo({
      'window.innerWidth': window.innerWidth,
      'window.innerHeight': window.innerHeight,
      'window.outerWidth': window.outerWidth,
      'window.outerHeight': window.outerHeight,
      'window.screen.width': window.screen.width,
      'window.screen.height': window.screen.height,
      'window.devicePixelRatio': window.devicePixelRatio,
      // @ts-ignore
      '!!window.chrome': !!window.chrome ? 'true' : 'false',
      // @ts-ignore
      '!!window.safari': !!window.safari ? 'true' : 'false',
      'window.navigator.userAgent': window.navigator.userAgent,
    });

    typeof window !== 'undefined' &&
      window.addEventListener('resize', function () {
        setBrowserInfo({
          'window.innerWidth': window.innerWidth,
          'window.innerHeight': window.innerHeight,
          'window.outerWidth': window.outerWidth,
          'window.outerHeight': window.outerHeight,
          'window.screen.width': window.screen.width,
          'window.screen.height': window.screen.height,
          'window.devicePixelRatio': window.devicePixelRatio,
          // @ts-ignore
          '!!window.chrome': !!window.chrome ? 'true' : 'false',
          // @ts-ignore
          '!!window.safari': !!window.safari ? 'true' : 'false',
          'window.navigator.userAgent': window.navigator.userAgent,
        });
      });
  }, []);

  return (
    <div className="w-screen h-screen flex flex-col space-y-2 p-20 font-mono text-2xl font-medium">
      {Object.keys(browserInfo)?.map(key => (
        <span
          key={browserInfo[key]}
          className="w-full flex flex-row justify-between text-green-400"
        >
          <span className="text-left">{key}</span>
          <span className="text-right">{browserInfo[key]}</span>
        </span>
      ))}
    </div>
  );
};

export default Debugger;
