import { toSum, identity, to, toAverage } from '../../libs/say-it';
import { toFeq } from '../../libs/say-it';
import { IIdealPlan, IPlan, IPlanEvaluator } from '../concepts';

export class PlanEvaluator implements IPlanEvaluator {
  static create(ref: IIdealPlan) {
    return new PlanEvaluator(ref);
  }

  constructor(readonly ref: IIdealPlan) {}

  distanceFromIdealPlan(plan: IPlan): number {
    return plan
      .toClusters(this.ref.toKotterStep())
      .map(this.ref.toAbsDistanceFromStep())
      .reduce(toSum(), 0);
  }

  dispersionOf(plan: IPlan): number {
    return plan
      .toClusters(this.ref.toKotterStep())
      .reduce(toFeq(identity()), [])
      .map(to(1))
      .map(v => (v - 1) / 3)
      .reduce(toAverage(), 0);
  }

  invertedEntropy(plan: IPlan): number {
    return 1 / Math.pow(this.distanceFromIdealPlan(plan) + 1, 1 / 6);
  }

  lossDueToIncompleteness(plan: IPlan) {
    return plan.lossDueToMissingActions(this.ref.actions()) * 0.25;
  }

  lossDueToPUIncompleteness(plan: IPlan) {
    return plan.lossDueToMissingActions(this.ref.puActions()) * 0.25;
  }

  lossDueToPEOUIncompleteness(plan: IPlan) {
    return plan.lossDueToMissingActions(this.ref.peouActions()) * 0.25;
  }

  lossDueToDisturbingActions(plan: IPlan) {
    return plan.lossDueToDisturbingActions(this.ref.disturbingActions()) * 0.25;
  }

  maxAcceptanceRateOf(plan: IPlan) {
    return (
      1 -
      [
        this.lossDueToIncompleteness(plan),
        this.lossDueToPUIncompleteness(plan),
        this.lossDueToPEOUIncompleteness(plan),
        this.lossDueToDisturbingActions(plan),
      ].reduce(toSum(), 0)
    );
  }

  acceptanceRateOf(plan: IPlan): number {
    return this.maxAcceptanceRateOf(plan) * this.invertedEntropy(plan);
  }

  lossDueToEntropy(plan: IPlan) {
    return this.maxAcceptanceRateOf(plan) - this.acceptanceRateOf(plan);
  }

  phasePerformance(plan: IPlan, phase: string) {
    const actionsByPhase = this.ref.actionsByPhase(phase);
    return plan.countActionIfIncluded(actionsByPhase) / actionsByPhase.length;
  }
}
