import {
  doc,
  Firestore,
  onSnapshot,
  setDoc,
  Unsubscribe,
} from 'firebase/firestore';
import { GetState, SetState, StateCreator, StoreApi } from 'zustand';

export type ZustandState = Record<string, unknown>;

export type LiveState<T extends ZustandState> = T & {
  readonly live: {
    observe(stateId: string): void;
    sync(stateId: string): void;
  };
};

export const live =
  <T extends ZustandState>(
    config: StateCreator<T, SetState<T>, GetState<LiveState<T>>, StoreApi<T>>
  ) =>
  (opts: {
    filter: (s: T) => Partial<T>;
    db: Firestore;
  }): StateCreator<
    LiveState<T>,
    SetState<LiveState<T>>,
    GetState<LiveState<T>>,
    StoreApi<LiveState<T>>
  > =>
  (set: any, get, api: any) => {
    let isObserving = true;
    let stateId: string = null;
    let unsubscribe: Unsubscribe = () => {};

    const store = config(
      s => {
        // const oldState = get();
        set(s);

        if (isObserving) return;

        const stateToSync = opts.filter(get());

        setDoc(doc(opts.db, 'live', stateId), stateToSync as any)
          .then(() => console.log('live state dumped'))
          .catch(err => console.log('error while dumping live state:', err));
      },
      get,
      api
    );

    function observe(stateId: string) {
      console.log('start observing...', stateId);
      isObserving = true;
      unsubscribe = onSnapshot(doc(opts.db, 'live', stateId), doc => {
        console.log('new state ready', doc.data());
        set(doc.data() as any);
      });
    }

    function sync(id: string) {
      console.log('start syncing...', id);
      unsubscribe();
      isObserving = false;
      stateId = id;
    }

    return {
      ...store,
      live: {
        observe,
        sync,
      },
    };
  };
