import { useMemo } from 'react';
import Lottie from 'react-lottie-player';
import animation1 from '../../lottie-animations/104649-like-animation.json';
import animation2 from '../../lottie-animations/104650-dislike.json';
import { useGame } from '../../store/game';
import { percent, units } from '../layout/formatters';
import { CoinIcon } from '../game/coins-icon';
import { useMessages } from '../../store/messages';
import { ExtractMultiLines } from '../utils/extract-multi-lines';

const FinalResults = () => {
  const t = useMessages(({ print }) => print);
  const finalPlanAcceptanceRate: number = useGame(({ acceptanceRate }) =>
    acceptanceRate('executive')
  );
  const draftPlanAcceptanceRate: number = useGame(({ acceptanceRate }) =>
    acceptanceRate('draft')
  );

  const { roundedFinalPlanAcceptanceRate, roundedDraftPlanAcceptanceRate } =
    useMemo(
      () => ({
        roundedFinalPlanAcceptanceRate: Number(
          finalPlanAcceptanceRate.toFixed(4)
        ),
        roundedDraftPlanAcceptanceRate: Number(
          draftPlanAcceptanceRate.toFixed(4)
        ),
      }),
      [finalPlanAcceptanceRate, draftPlanAcceptanceRate]
    );

  const { initialBudget, planTotalCost, budgetSpent } = useGame(s => ({
    planTotalCost: s.planTotalCost('executive'),
    budgetSpent: s.budgetSpent,
    initialBudget: s.initialBudget,
  }));

  const ceoFeedbackText = useMemo(() => {
    const score: number = roundedFinalPlanAcceptanceRate * 100;

    if (score > 71) {
      if (roundedDraftPlanAcceptanceRate < roundedFinalPlanAcceptanceRate)
        return (
          <Text
            adoptionScore={roundedFinalPlanAcceptanceRate}
            textualFeedback={t('final-results-text-positive-feedback')}
            draftAdoptionScore={roundedDraftPlanAcceptanceRate}
            potentialTextualFeedback={t('draft-plan-text-negative-feedback')}
            greetingWorkDone={t('positive-work-done')}
          />
        );

      if (roundedDraftPlanAcceptanceRate > roundedFinalPlanAcceptanceRate)
        return (
          <Text
            adoptionScore={roundedFinalPlanAcceptanceRate}
            textualFeedback={t('final-results-text-positive-feedback')}
            draftAdoptionScore={roundedDraftPlanAcceptanceRate}
            potentialTextualFeedback={t('draft-plan-text-positive-feedback')}
            greetingWorkDone={t('positive-work-done')}
          />
        );

      if (roundedFinalPlanAcceptanceRate === roundedDraftPlanAcceptanceRate)
        return (
          <Text
            adoptionScore={roundedFinalPlanAcceptanceRate}
            textualFeedback={t('final-results-text-positive-feedback')}
            draftAdoptionScore={roundedDraftPlanAcceptanceRate}
            potentialTextualFeedback={t('draft-plan-text-neutral-feedback')}
            greetingWorkDone={t('positive-work-done')}
          />
        );
    }

    if (score > 31) {
      if (roundedDraftPlanAcceptanceRate < roundedFinalPlanAcceptanceRate)
        return (
          <Text
            adoptionScore={roundedFinalPlanAcceptanceRate}
            textualFeedback={t('final-results-text-neutral-feedback')}
            draftAdoptionScore={roundedDraftPlanAcceptanceRate}
            potentialTextualFeedback={t('draft-plan-text-negative-feedback')}
            greetingWorkDone={t('neutral-work-done')}
          />
        );

      if (roundedDraftPlanAcceptanceRate > roundedFinalPlanAcceptanceRate)
        return (
          <Text
            adoptionScore={roundedFinalPlanAcceptanceRate}
            textualFeedback={t('final-results-text-neutral-feedback')}
            draftAdoptionScore={roundedDraftPlanAcceptanceRate}
            potentialTextualFeedback={t('draft-plan-text-positive-feedback')}
            greetingWorkDone={t('neutral-work-done')}
          />
        );

      if (roundedFinalPlanAcceptanceRate === roundedDraftPlanAcceptanceRate)
        return (
          <Text
            adoptionScore={roundedFinalPlanAcceptanceRate}
            textualFeedback={t('final-results-text-neutral-feedback')}
            draftAdoptionScore={roundedDraftPlanAcceptanceRate}
            potentialTextualFeedback={t('draft-plan-text-neutral-feedback')}
            greetingWorkDone={t('negative-work-done')}
          />
        );
    }

    if (roundedDraftPlanAcceptanceRate < roundedFinalPlanAcceptanceRate)
      return (
        <Text
          adoptionScore={roundedFinalPlanAcceptanceRate}
          textualFeedback={t('final-results-text-negative-feedback')}
          draftAdoptionScore={roundedDraftPlanAcceptanceRate}
          potentialTextualFeedback="avrebbe portato a risultati addirittura peggiori"
          greetingWorkDone={t('negative-work-done')}
        />
      );

    if (roundedDraftPlanAcceptanceRate > roundedFinalPlanAcceptanceRate)
      return (
        <Text
          adoptionScore={roundedFinalPlanAcceptanceRate}
          textualFeedback={t('final-results-text-negative-feedback')}
          draftAdoptionScore={roundedDraftPlanAcceptanceRate}
          potentialTextualFeedback={t('draft-plan-text-positive-feedback')}
          greetingWorkDone={t('negative-work-done')}
        />
      );

    if (roundedFinalPlanAcceptanceRate === roundedDraftPlanAcceptanceRate)
      return (
        <Text
          adoptionScore={roundedFinalPlanAcceptanceRate}
          textualFeedback={t('final-results-text-negative-feedback')}
          draftAdoptionScore={roundedDraftPlanAcceptanceRate}
          potentialTextualFeedback={t('draft-plan-text-neutral-feedback')}
          greetingWorkDone={t('negative-work-done')}
        />
      );
  }, [roundedFinalPlanAcceptanceRate, roundedDraftPlanAcceptanceRate]);

  return (
    <div className="relative p-12 overflow-y-scroll font-sans scrollbar-document">
      <Lottie
        className="absolute h-32 top-2 3xl:h-48 3xl:-top-8 right-6"
        animationData={
          roundedFinalPlanAcceptanceRate > 0.31 ? animation1 : animation2
        }
        play
      />
      <span className="flex flex-row items-center mb-8 space-x-2">
        <p className="text-sm font-semibold text-gray-900 xl:text-base 2xl:text-lg 3xl:text-4xl">
          {t('final-results-title')}
        </p>
      </span>

      <div className="text-xs text-gray-900 xl:text-sm 2xl:text-base">
        {ceoFeedbackText}
      </div>

      <div>
        <div className="flex flex-col">
          <div className="flex flex-row justify-between px-2 py-1 bg-gray-200/70">
            <span>{t('draft-plan-acceptance-rate')}:</span>
            <span className="flex flex-row items-center font-semibold">
              {percent(roundedDraftPlanAcceptanceRate, 2)}
            </span>
          </div>
          <div className="flex flex-row justify-between px-2 py-1">
            <span>{t('executive-plan-acceptance-rate')}:</span>
            <span className="flex flex-row items-center font-semibold">
              {percent(roundedFinalPlanAcceptanceRate, 2)}
            </span>
          </div>
          <div className="flex flex-row justify-between px-2 py-1 bg-gray-200/70">
            <span>{t('plan-real-cost')}:</span>
            <span className="flex flex-row items-center space-x-1">
              <p className="font-semibold">
                {units(planTotalCost)}/{units(initialBudget)}
              </p>
              <CoinIcon />
            </span>
          </div>
          <div className="flex flex-row justify-between px-2 py-1">
            <span>{t('sai-support-title')}:</span>
            <span className="flex flex-row items-center space-x-1">
              <p className="font-semibold">
                {percent(budgetSpent / initialBudget)}
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalResults;

const Text = ({
  adoptionScore,
  textualFeedback,
  draftAdoptionScore,
  potentialTextualFeedback,
  greetingWorkDone,
}: {
  adoptionScore: number;
  textualFeedback: string;
  draftAdoptionScore: number;
  potentialTextualFeedback: string;
  greetingWorkDone: string;
}) => {
  const t = useMessages(({ print }) => print);
  const scenarioName = useGame(({ scenarioName }) => scenarioName);
  const timeSpent = t(`time-spent-${scenarioName}`);
  return (
    <div className="flex flex-col my-4 space-y-3">
      {ExtractMultiLines(
        t('final-result-text-content-wrapper', {
          timeSpent,
          executiveAdoptionRate: percent(adoptionScore, 2),
          textualFeedback,
          draftAdoptionRate: percent(draftAdoptionScore, 2),
          potentialTextualFeedback,
          greetingWorkDone,
        })
      )}
    </div>
  );
};
