import { AnimatePresence, motion } from 'framer-motion';
import Lottie from 'react-lottie-player';
import animation from '../../lottie-animations/62719-solar-powered-house.json';
import { useGame } from '../../store/game';
import { useMessages } from '../../store/messages';

interface IProps {
  advancingFromPhase: 'draft' | 'final';
  setAdvancingFromPhase: (phaseId: 'draft' | 'final') => void;
}

const AdvancementOverlay = ({
  advancingFromPhase,
  setAdvancingFromPhase,
}: IProps) => {
  const t = useMessages(({ print }) => print);
  const scenarioName = useGame(({ scenarioName }) => scenarioName);

  return (
    <AnimatePresence exitBeforeEnter>
      {advancingFromPhase && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
          className="w-screen h-screen absolute left-0 top-0 p-20 flex flex-col items-center justify-center z-[200] bg-black/80"
        >
          <Lottie
            animationData={animation}
            play
            onLoopComplete={() => {
              setAdvancingFromPhase(undefined);
            }}
          />
          <p className="text-4xl font-bold text-white uppercase">
            {advancingFromPhase === 'draft'
              ? t('advancement-overlay-draft')
              : scenarioName === 'crisis'
              ? t('advancement-overlay-executive-crisis')
              : t('advancement-overlay-executive-neutral')}{' '}
            {t('advancement-overlay-later')}...
          </p>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AdvancementOverlay;
