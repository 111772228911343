import { useEffect, useState } from 'react';

export default function useQueryParam(param: string): string {
  const [paramValue, setParamValue] = useState<string>();

  useEffect(() => {
    if (typeof window === 'undefined') return;

    setParamValue(
      new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop as string),
      })?.[param]
    );
  }, [param]);

  return paramValue;
}
