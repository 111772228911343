import { useEffect, useState } from 'react';
import { Howl } from 'howler';
import qs from 'query-string';
import Timeline from '../../core/timeline';
import ORIGINAL_CONVERSATION from '../../core/conversation';
import OBSERVER_CONVERSATION from '../../core/conversation-observer';
import ConversationHeading from './conversation-heading';
import ConversationInput from './conversation-input';
import Messages from './messages';
import { useGame } from '../../store/game';
import VolumeToggle from './volume-toggle';
import { ADVANCEMENT_CUTSCENE_DURATION } from '../../core/constants';
import secondsToMilliseconds from '../../core/secondsToMilliseconds';
import buildAssetPath from '../../core/buildAssetPath';
import { Conversation } from '../../store/game/concepts/messages';
import { useObservations } from '../../store/observations';
import useQueryParam from '../../hooks/useQueryParam.hook';
import getLEDSPClient from '../../utils/get-ledsp-client';

const Statuses = {
  Idle: 0,
  Writing: 1,
  WaitingForAnswer: 2,
};

interface IProps {
  setAdvancingFromPhase: (phaseId: 'draft' | 'final') => void;
}

const Chat = ({ setAdvancingFromPhase }: IProps) => {
  const [timeline, setTimeline] = useState<Timeline>();
  const [status, setStatus] = useState(Statuses.Idle);
  const [answer, setAnswer] = useState<{ text: string; attachment?: string }>();
  const [question, setQuestion] = useState<string>();
  const [availableAnswers, setAnswers] = useState<
    { text: string; attachment?: string }[]
  >([]);

  const fastMessages: string = useQueryParam('fastMessages');

  const sessionId = useGame(({ sessionId }) => sessionId);
  const send = useGame(({ send }) => send);
  const advanceConversationCursor = useGame(
    ({ advanceConversationCursor }) => advanceConversationCursor
  );
  const messages = useGame(({ pastMessages }) => pastMessages);
  const cursorOnOriginalConversation = useGame(
    ({ cursorOnOriginalConversation }) => cursorOnOriginalConversation
  );

  const { isSoundOn, toggleVolume } = useGame(
    ({ isSoundOn, toggleVolume }) => ({ isSoundOn, toggleVolume })
  );
  const endGameReturnPath = useGame(
    ({ endGameReturnPath }) => endGameReturnPath
  );
  const releaseDoc = useGame(({ releaseDocument }) => releaseDocument);
  const submitDraftPlan = useGame(({ submitDraftPlan }) => submitDraftPlan);
  const submitExecutionPlan = useGame(
    ({ submitExecutionPlan }) => submitExecutionPlan
  );
  const observations = useObservations();
  const registerObservations = useGame(
    ({ registerObservations }) => registerObservations
  );
  const { gameId, teamName, playerId, isObserver } = useGame(
    ({ gameId, teamName, playerId, isObserver }) => ({
      gameId,
      teamName,
      playerId,
      isObserver,
    })
  );

  const incomingMessageSFX = new Howl({
    src: [buildAssetPath('sfx/message-incoming.wav')],
    volume: 0.6,
  });

  const outgoingMessageSFX = new Howl({
    src: [buildAssetPath('sfx/message-outgoing.wav')],
    volume: 0.6,
  });

  useEffect(() => {
    timeline?.setFastForwarding(!!fastMessages);
  }, [fastMessages]);

  const conversationToTimeline = (conversation: Conversation) => {
    const timeline = new Timeline();

    conversation.map(message => {
      if (message.type === 'text')
        timeline
          .add(
            () => setStatus(Statuses.Writing),
            realisticWritingTime(message.text)
          )
          .add(() => setStatus(Statuses.Idle), 200)
          .add(() => {
            send({ type: 'incoming', text: message.text });
            advanceConversationCursor();

            if (isSoundOn()) incomingMessageSFX.play();
          }, secondsToMilliseconds(message.timeToRead));

      if (message.type === 'external-link')
        timeline
          .add(
            () => setStatus(Statuses.Writing),
            realisticWritingTime(message.text)
          )
          .add(() => setStatus(Statuses.Idle), 200)
          .add(() => {
            send({
              type: 'incoming',
              text: message.text,
              externalLink: `${process.env.DEBRIEF_URL}?session=${sessionId}&teamName=${teamName}`,

              // TODO restore this when LEDSP is ready
              // message.link === 'endGameReturnPath'
              //   ? endGameReturnPath
              //   : message.link,
            });
            advanceConversationCursor();

            if (isSoundOn()) incomingMessageSFX.play();
          }, secondsToMilliseconds(message.timeToRead));

      if (message.type === 'attachment')
        timeline
          .add(() => setStatus(Statuses.Writing), 800)
          .add(() => setStatus(Statuses.Idle), 200)
          .add(() => {
            send({
              type: 'incoming',
              text: 'attachment',
              attachment: message.attachment,
            });
            advanceConversationCursor();

            if (isSoundOn()) incomingMessageSFX.play();

            switch (message.attachment) {
              case 'minuta':
                !isObserver &&
                  getLEDSPClient().sendGameProgressEvent({
                    gameId: gameId,
                    teamId: teamName,
                    playerId: playerId,
                    step: 'Introduction',
                    stage: 'Explanations',
                    eventType: 'game-stage-entered',
                  });
                break;

              case 'rules':
                !isObserver &&
                  getLEDSPClient().sendGameProgressEvent({
                    gameId: gameId,
                    teamId: teamName,
                    playerId: playerId,
                    step: 'Introduction',
                    stage: 'Rules',
                    eventType: 'game-stage-entered',
                  });
                break;

              case 'actions-portfolio':
                !isObserver &&
                  getLEDSPClient().sendGameProgressEvent({
                    gameId: gameId,
                    teamId: teamName,
                    playerId: playerId,
                    step: 'Introduction',
                    stage: 'Portfolio',
                    eventType: 'game-stage-entered',
                  });
                break;

              case 'draft-response':
                !isObserver &&
                  getLEDSPClient().sendGameProgressEvent({
                    gameId: gameId,
                    teamId: teamName,
                    playerId: playerId,
                    step: 'DraftPlan',
                    stage: 'Feedback',
                    eventType: 'game-stage-entered',
                  });
                break;

              case 'final-results':
                !isObserver &&
                  getLEDSPClient().sendGameProgressEvent({
                    gameId: gameId,
                    teamId: teamName,
                    playerId: playerId,
                    step: 'ExecutivePlan',
                    stage: 'Feedback',
                    eventType: 'game-stage-entered',
                  });
                break;

              default:
                break;
            }
          }, 2_000)
          .add(() => {}, 2_000);

      if (message.type === 'question')
        timeline
          .add(() => setStatus(Statuses.WaitingForAnswer), 200)
          .add(() => {
            timeline.pause() as any;

            setQuestion(message.questionId);
            setAnswers(message.options);
          }, 0);

      if (message.type === 'conditional-text') {
        timeline
          .add(
            () => setStatus(Statuses.Writing),
            secondsToMilliseconds(message.timeToWrite)
          )
          .add(() => setStatus(Statuses.Idle), 200)
          .add(() => {
            send({ type: 'incoming', text: message.text });
            advanceConversationCursor();
            if (isSoundOn()) incomingMessageSFX.play();
          }, secondsToMilliseconds(message.timeToRead));
      }

      if (message.type === 'chapter-end')
        timeline
          .add(() => setAdvancingFromPhase(message.phaseId), 0)
          .add(() => {
            timeline.pause() as any;
            setTimeout(
              () => {
                timeline?.resume().play();
                advanceConversationCursor();
              },
              fastMessages ? 1_000 : ADVANCEMENT_CUTSCENE_DURATION + 1_000
            );
          }, 0);
    });

    return timeline;
  };

  useEffect(() => {
    setTimeline(
      conversationToTimeline(
        isObserver
          ? OBSERVER_CONVERSATION.slice(cursorOnOriginalConversation)
          : ORIGINAL_CONVERSATION.slice(cursorOnOriginalConversation)
      )
    );
  }, []);

  useEffect(() => {
    if (!timeline) return;

    timeline.play();
  }, [timeline]);

  const submitAnswer = async () => {
    try {
      if (isSoundOn()) outgoingMessageSFX.play();

      if (!answer) return;

      setStatus(Statuses.Idle);
      send({
        type: 'outgoing',
        ...(answer as any),
      });
      advanceConversationCursor();

      if (question === 'plan-explanation') {
        releaseDoc('draft-plan');
        releaseDoc('executive-plan');

        !isObserver &&
          getLEDSPClient().sendGameProgressEvent({
            gameId: gameId,
            teamId: teamName,
            playerId: playerId,
            step: 'DraftPlan',
            stage: 'Planning',
            eventType: 'game-stage-entered',
          });
      }

      if (question === 'draft-plan-confirm') submitDraftPlan();

      if (question === 'draft-feedback-read')
        !isObserver &&
          getLEDSPClient().sendGameProgressEvent({
            gameId: gameId,
            teamId: teamName,
            playerId: playerId,
            step: 'ExecutivePlan',
            stage: 'Planning',
            eventType: 'game-stage-entered',
          });

      if (question === 'executive-plan-confirm') {
        submitExecutionPlan();

        await registerObservations(observations);

        !isObserver &&
          getLEDSPClient().sendGameProgressEvent({
            gameId: gameId,
            teamId: teamName,
            playerId: playerId,
            eventType: 'game-ended',
            cleared: true,
          });
      }

      if (question === 'final-results-read')
        !isObserver &&
          getLEDSPClient().sendGameProgressEvent({
            gameId: gameId,
            teamId: teamName,
            playerId: playerId,
            step: 'Endgame',
            stage: 'Outro',
            eventType: 'game-stage-entered',
          });

      setAnswer(undefined);

      setTimeout(() => timeline?.resume().play(), 2_000);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <VolumeToggle
        isSoundOn={isSoundOn()}
        handleVolumeToggle={() => toggleVolume()}
      />

      <ConversationHeading />
      <Messages status={status} messages={messages} />
      <ConversationInput
        questionId={question}
        setAnswer={setAnswer}
        availableAnswers={availableAnswers}
        status={status}
        answer={answer}
        submitAnswer={submitAnswer}
      />
    </>
  );
};

export default Chat;

const realisticWritingTime = (message: string) => 250 + message.length * 50;
