import { Fragment } from 'react';

export const ListOfWords: React.FC<{ children: React.ReactNode[] }> = props => (
  <>
    {props.children.map((child, i, list) => (
      <Fragment key={i}>
        {child}
        {separatorByIndex(i, list.length)}
      </Fragment>
    ))}
  </>
);

function separatorByIndex(index: number, length: number) {
  if (index < length - 2) return ', ';
  if (index < length - 1) return ' ed ';
  return null;
}
