import produce from 'immer';
import { IFeed } from '../concepts/feed';
import { ILogger } from '../concepts/logger';
import { IPlan, Plan } from '../concepts/plan';
import { StoreSlice } from '../../utils/store-slice';
import { IBudget } from '../concepts/budget';
import {
  ifIn,
  ifNotEqual,
  ifValueOf,
  includes,
  isEqualTo,
  notIn,
} from '../../utils/predicates';
import { to } from '../../utils/mappers';
import { toSum } from '../../utils/reducers';
import { IActions } from '../concepts/actions';
import * as move from 'array-move';
import { IDocuments } from '../concepts/documents';
import { evaluatePlan } from '../../../mechanics/services/evaluate-plan';
import { IGame } from '../concepts/game';

interface IPrivate {
  planExists(planId: string): boolean;
  findPlan(planId: string): Plan;
}

export const create: StoreSlice<
  IPlan & IPrivate,
  ILogger & IFeed & IBudget & IActions & IDocuments & IGame
> = (set, get) => {
  return {
    scenario: {
      strategyToAdopt: 'top-down',
      phaseWeights: [
        { phaseId: 'preparation', targetScore: 45 },
        { phaseId: 'communication', targetScore: 30 },
        { phaseId: 'execution', targetScore: 25 },
      ],
    },
    plans: [
      {
        id: 'draft',
        actions: [],
        pickedActions: [],
        isSubmitted: false,
      },
    ],
    currentPlan: 'draft',

    findPlan(planId) {
      return get().plans.find(ifValueOf('id', isEqualTo(planId))) || nullPlan();
    },

    planExists(planId) {
      return get().findPlan(planId).id !== '--null--';
    },

    isActionInPlan(planId, action) {
      const plan = get().findPlan(planId);
      return plan.actions.includes(action);
    },

    plansWithAction(action) {
      return get()
        .plans.filter(ifValueOf('actions', includes(action)))
        .map(to('id'));
    },

    includeActionInPlan(planId, action, after) {
      if (get().isActionInPlan(planId, action)) {
        get().say(`Action «${action}» is already in plan`);
        return;
      }

      const plan = get().findPlan(planId);

      after = (Number.isInteger(after) && after) || plan.actions.length;

      set(
        produce((s: IPlan) => {
          const plan = s.plans.find(ifValueOf('id', isEqualTo(planId)));
          if (!plan) return s;
          plan.actions.splice(after, 0, action);
        })
      );
    },

    moveActionInPlan(planId, action, after = 0) {
      set(
        produce((s: IPlan) => {
          const plan = s.plans.find(ifValueOf('id', isEqualTo(planId)));
          if (!plan) return s;
          move.arrayMoveMutable(
            plan.actions,
            plan.actions.indexOf(action),
            after
          );
        })
      );
    },

    moveActionInPlanAfterAction(
      planId: string,
      actionId: string,
      targetActionId: string
    ) {
      get().moveActionInPlan(
        planId,
        actionId,
        get().actionPosition(planId, targetActionId)
      );
    },

    actionPosition(planId: string, targetActionId: string) {
      return get().actionsInPlan(planId).indexOf(targetActionId);
    },

    replaceActionsInPlan(planId, actions) {
      set(
        produce((s: IPlan) => {
          const plan = s.plans.find(ifValueOf('id', isEqualTo(planId)));
          if (!plan) return s;
          plan.actions = actions;
        })
      );
    },

    excludeActionFromPlan(planId, action) {
      set(
        produce((s: IPlan) => {
          const plan = s.plans.find(ifValueOf('id', isEqualTo(planId)));
          if (!plan) return s;
          plan.actions = plan.actions.filter(ifNotEqual(action));
        })
      );
    },

    actionsInPortfolio() {
      if (!get().isPlanSubmitted('draft')) return get().actionIds();

      return [
        ...get().actionsInPlan('draft'),
        ...get().actionsNotInPlan('draft'),
      ];
    },

    actionsInPlan(planId) {
      return get().findPlan(planId).actions;
    },

    actionsNotInPlan(planId) {
      const plan = get().findPlan(planId);
      return get().actionIds().filter(notIn(plan.actions));
    },

    searchPlannableActions(planId: string, pattern?: string) {
      return get()
        .searchActions(pattern)
        .filter(ifIn(get().actionsNotInPlan(planId)));
    },

    planTotalCost(planId) {
      const plan = get().findPlan(planId);
      return plan.actions.map(get().actionCost).reduce(toSum, 0);
    },

    planResidualBudget(planId) {
      return Math.max(0, get().availableBudget() - get().planTotalCost(planId));
    },

    isPlanFeasible(planId) {
      return get().availableBudget() >= get().planTotalCost(planId);
    },

    /** Performance model */
    acceptanceRate(planId) {
      const ev = evaluatePlan(
        get().findPlan(planId).actions,
        get().scenarioName
      );
      return ev.acceptanceRate;
    },

    planEvaluation(planId) {
      return evaluatePlan(get().findPlan(planId).actions, get().scenarioName);
    },

    // TODO New entries, check this
    submitDraftPlan() {
      set(
        produce((s: IPlan) => {
          const plan = s.plans.find(ifValueOf('id', isEqualTo('draft')));
          plan.isSubmitted = true;
          s.currentPlan = 'executive';
          s.plans.push({
            id: 'executive',
            actions: plan.actions,
            pickedActions: plan.pickedActions,
            isSubmitted: false,
          });
        })
      );

      get().releaseDocument('executive-plan');
    },

    isPlanSubmitted(planId) {
      return get().findPlan(planId).isSubmitted;
    },

    submitExecutionPlan() {
      set(
        produce((s: IPlan) => {
          const plan = s.plans.find(ifValueOf('id', isEqualTo('executive')));
          plan.isSubmitted = true;
        })
      );
    },

    isPlanVisible(planId) {
      const plan = get().findPlan(planId);
      return plan.id !== '--null--';
    },

    resetExecutionPlan() {
      set(
        produce((s: IPlan) => {
          const draftPlan = s.plans.find(ifValueOf('id', isEqualTo('draft')));
          s.plans = [
            draftPlan,
            {
              id: 'executive',
              actions: draftPlan.actions,
              pickedActions: draftPlan.pickedActions,
              isSubmitted: false,
            },
          ];
        })
      );
    },
  };
};

/** factories */

const nullPlan = (): Plan => ({
  id: '--null--',
  actions: [],
  pickedActions: [],
  isSubmitted: false,
});
