import { useMemo } from 'react';
import { useGame } from '../../store/game';
import { useMessages } from '../../store/messages';
import CEOEmail from './ceo-email';

const DraftPlanResponse = () => {
  const t = useMessages(({ print }) => print);
  const acceptanceRate: number = useGame(({ acceptanceRate }) =>
    acceptanceRate('draft')
  );

  const ceoFeedbackText = useMemo(() => {
    const score: number = acceptanceRate * 100;

    return score > 71
      ? t('draft-response-positive-feedback')
      : score > 31
      ? t('draft-response-neutral-feedback')
      : t('draft-response-negative-feedback');
  }, [acceptanceRate]);

  return (
    <CEOEmail
      title={t('draft-response-email-subject')}
      text={ceoFeedbackText}
    />
  );
};

export default DraftPlanResponse;
