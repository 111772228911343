import { useState } from 'react';
import { motion } from 'framer-motion';
import Explorer from '../computer/explorer';
import WatchFrame from '../watch/watch-frame';
import ComputerFrame from '../computer/computer-frame';
import PhoneFrame from '../phone/phone-frame';
import Chat from '../phone/chat';
import AdvancementOverlay from '../game/advancement-overlay';
import useMousePosition from '../../hooks/useMousePosition.hook';
import buildOptimizedAssetPath from '../../core/buildOptimizedAssetPath';
import useScaleFactor from '../../hooks/useScaleFactor.hook';
import BrandedHeader from './branded-header';

const MainLayout = () => {
  const [advancingFromPhase, setAdvancingFromPhase] = useState<
    'draft' | 'final'
  >();

  const { x: mouseX, y: mouseY } = useMousePosition();
  const scaleFactor: number = useScaleFactor();

  return (
    <>
      {/*<div className="flex flex-col items-center justify-center w-screen h-screen p-8 lg:hidden text-4xl">
        <p>Please use a higher resolution display to play this game.</p>
      </div>*/}

      <div className="flex flex-col w-screen h-screen overflow-hidden">
        <BrandedHeader scaleFactor={scaleFactor} variant="internal" />

        <WatchFrame />

        <motion.div
          animate={{
            x: mouseX ? -mouseX / 450 : 0,
            y: mouseY ? -mouseY / 450 : 0,
            scale: 1.0,
          }}
          transition={{
            duration: 0.2,
            ease: 'linear',
          }}
          className="w-screen h-screen overflow-hidden absolute bottom-0 bg-cover bg-bottom"
          style={{
            backgroundImage: `url('${buildOptimizedAssetPath('images/bg_room', [
              'avif',
              'webp',
            ])}')`,
          }}
        />

        <div
          className="w-full h-[100px] overflow-hidden absolute bottom-0 bg-cover bg-bottom"
          style={{
            backgroundImage: `url('${buildOptimizedAssetPath('images/table', [
              'avif',
              'jpg',
            ])}')`,
          }}
        />

        <div className="bg-transparent flex flex-row w-full flex-grow gap-4 py-4 xl:py-5 2xl:py-10 relative overflow-clip">
          <div
            className="relative w-1/4 h-full flex items-center justify-center z-20"
            //-translate-y-32 lg:-translate-y-20 xl:-translate-y-32 2xl:-translate-y-16"
          >
            <div className="p-px xl:p-1 2xl:p-2 h-full w-full m-auto translate-x-16 xl:translate-x-8 -translate-y-4 xl:-translate-y-8">
              <PhoneFrame>
                <Chat setAdvancingFromPhase={setAdvancingFromPhase} />
              </PhoneFrame>
            </div>
          </div>

          <div className="flex-grow h-full 2xl:p-8 relative transform-gpu -translate-y-4 z-10">
            <ComputerFrame>
              <Explorer />
            </ComputerFrame>
          </div>
        </div>
      </div>

      <AdvancementOverlay
        advancingFromPhase={advancingFromPhase}
        setAdvancingFromPhase={setAdvancingFromPhase}
      />
    </>
  );
};

export default MainLayout;
