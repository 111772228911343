import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import animation from '../../lottie-animations/lf30_editor_iarxkedo.json';
import Lottie from 'react-lottie-player';
import ReactMarkdown from 'react-markdown';
import { useMessages } from '../../store/messages';

const Message = ({
  text,
  type,
  attachment,
  externalLink,
  onAttachmentDownload,
}: {
  text: string;
  type: string;
  attachment?: string;
  externalLink?: string;
  onAttachmentDownload?: () => void;
}) => {
  const t = useMessages(({ print }) => print);
  const [isSeen, setSeen] = useState(false);
  const [play, setPlay] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setSeen(true);
    }, 600 + Math.random() * 200);
  }, []);

  const handleDownload = () => {
    onAttachmentDownload();
    setPlay(false);
  };

  return (
    <motion.div
      className={clsx(
        'w-5/6 relative block mb-1 2xl:mb-2',
        type === 'incoming'
          ? 'rounded-r-lg 2xl:rounded-r-xl rounded-bl-lg xl:rounded-bl-xl bg-blue-600 mr-auto ml-1'
          : 'rounded-l-lg 2xl:rounded-l-xl rounded-br-lg xl:rounded-br-xl bg-green-600 ml-auto mr-1'
      )}
      initial={{ opacity: 0, scale: 0.9, left: -10 }}
      animate={{ opacity: 1, scale: 1, left: 0 }}
      transition={{ duration: 0.28 }}
    >
      <span
        className={clsx(
          'absolute top-0 w-0 h-0 border-t-8 border-l-4 border-r-4 border-l-transparent border-r-transparent',
          type === 'incoming'
            ? '-left-1 border-t-blue-600'
            : '-right-1 border-t-green-600'
        )}
      />
      <span
        className="relative block px-4 py-1 subpixel-antialiased leading-tight text-left text-white text-xxs lg:text-xs xl:text-sm 2xl:text-base 4xl:text-xl xl:py-2 2xl:py-3 lg:leading-tight xl:leading-tight 2xl:leading-tight 4xl:leading-tight"
        style={{
          transform: 'translateZ(0) perspective(1px)',
        }}
      >
        {attachment ? (
          <span
            className={clsx(
              'flex flex-row items-center justify-between pr-2',
              type === 'incoming' ? 'cursor-pointer' : ''
            )}
            onClick={() => setPlay(true)}
          >
            <span className="flex flex-row items-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="white"
              >
                <path d="M21.586 10.461l-10.05 10.075c-1.95 1.949-5.122 1.949-7.071 0s-1.95-5.122 0-7.072l10.628-10.585c1.17-1.17 3.073-1.17 4.243 0 1.169 1.17 1.17 3.072 0 4.242l-8.507 8.464c-.39.39-1.024.39-1.414 0s-.39-1.024 0-1.414l7.093-7.05-1.415-1.414-7.093 7.049c-1.172 1.172-1.171 3.073 0 4.244s3.071 1.171 4.242 0l8.507-8.464c.977-.977 1.464-2.256 1.464-3.536 0-2.769-2.246-4.999-5-4.999-1.28 0-2.559.488-3.536 1.465l-10.627 10.583c-1.366 1.368-2.05 3.159-2.05 4.951 0 3.863 3.13 7 7 7 1.792 0 3.583-.684 4.95-2.05l10.05-10.075-1.414-1.414z" />
              </svg>
              <span className="leading-tight text-xxs lg:text-xs xl:text-sm 2xl:text-base 4xl:text-xl xl:leading-tight 2xl:leading-tight">
                {t(attachment)}
                {attachment === 'minuta' &&
                  ` ${dayjs().subtract(3, 'day').format('DD/MM/YYYY')}`}
              </span>
            </span>
            {type === 'incoming' && (
              <Lottie
                className="w-5 h-5 ml-1 pointer-events-none 2xl:w-6 2xl:h-6 invert"
                animationData={animation}
                play={play}
                onLoopComplete={handleDownload}
              />
            )}
          </span>
        ) : (
          <div className="block message">
            {externalLink ? (
              <a href={externalLink} className="underline" target="_blank">
                {t(text)}
              </a>
            ) : (
              <ReactMarkdown>{t(text)}</ReactMarkdown>
            )}
          </div>
        )}
      </span>

      <span className="bottom-0 right-1 transform-gpu scale-[0.56] invert opacity-40 absolute">
        {isSeen ? <SeenIcon text={text} /> : <UnseenIcon text={text} />}
      </span>
    </motion.div>
  );
};

export default Message;

const SeenIcon = ({ text }) => (
  <motion.svg
    key={`${text}-read-status`}
    layoutId={`${text}-read-status`}
    layout
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
  >
    <path d="M24 6.278l-11.16 12.722-6.84-6 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.278zm-22.681 5.232l6.835 6.01-1.314 1.48-6.84-6 1.319-1.49zm9.278.218l5.921-6.728 1.482 1.285-5.921 6.756-1.482-1.313z" />
  </motion.svg>
);

const UnseenIcon = ({ text }) => (
  <motion.svg
    key={`${text}-read-status`}
    layoutId={`${text}-read-status`}
    layout
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
  >
    <path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z" />
  </motion.svg>
);
