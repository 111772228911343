interface IProps {
  isSoundOn: boolean;
  handleVolumeToggle: any;
}

const VolumeToggle = ({ isSoundOn, handleVolumeToggle }: IProps) => (
  <span
    className="w-4 h-4 right-10 top-2 absolute cursor-pointer"
    onClick={handleVolumeToggle}
  >
    {!isSoundOn ? (
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        className="transform-gpu -translate-y-1 -translate-x-px"
      >
        <path d="M22 1.269l-18.455 22.731-1.545-1.269 3.841-4.731h-1.827v-10h4.986v6.091l2.014-2.463v-3.628l5.365-2.981 4.076-5.019 1.545 1.269zm-10.986 15.926v.805l8.986 5v-16.873l-8.986 11.068z" />
      </svg>
    ) : (
      <svg width="16" height="16" viewBox="0 0 24 24">
        <path d="M9 18h-7v-12h7v12zm2-12v12l11 6v-24l-11 6z" />
      </svg>
    )}
  </span>
);

export default VolumeToggle;
