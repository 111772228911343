import { KotterSteps, StrategicFits } from '../concepts';

type ActionData = {
  id: string;
  name: string;
  description: string;
  strategicFit: StrategicFits;
  kotterStep: KotterSteps;
  score: number;
  cost: number;
  isPU?: boolean;
  isPEOU?: boolean;
};

export const actions: ActionData[] = [
  {
    id: 'azione-contesto',
    name: 'Analizzare il contesto socio-economico',
    description:
      "Raccogliete ulteriori informazioni (da fonti interne ed esterne all'azienda) per redigere rapidamente un report con un outlook del contesto socio-economico di riferimento",
    strategicFit: 'neutral',
    kotterStep: 'urgency',
    score: 1,
    cost: 5,
  },
  {
    id: 'azione-motivo',
    name: 'Comprendere perché cambiare',
    description:
      'Organizzate un meeting con un altro esperto di progetti di cambiamento organizzativo "complessi" con il cui aiuto mettere in luce, in breve tempo, le ragioni che richiedono un cambiamento',
    strategicFit: 'neutral',
    kotterStep: 'urgency',
    score: 1,
    cost: 5,
  },
  {
    id: 'azione-mappatura',
    name: 'Mappare la percezione sulla smart organization',
    description:
      'Analizzate il livello di percezione, da parte dei collaboratori, dell\'azienda come "smart organization',
    strategicFit: 'bottom-up',
    kotterStep: 'urgency',
    score: 1,
    cost: 10,
  },
  {
    id: 'azione-webinar',
    name: 'Incontrare il CEO',
    description:
      'Invitate il CEO a incontrare via webinar tutti i collaboratori, per illustrare loro le ragioni della necessità di far partire quanto prima un progetto di riorganizzazione aziendale',
    strategicFit: 'top-down',
    kotterStep: 'urgency',
    score: 1,
    cost: 10,
  },
  {
    id: 'azione-decisione',
    name: 'Decidere sul futuro',
    description:
      'Programmate un incontro tra il top management, due consiglieri interni (collaboratori di lunga esperienza in azienda e con elevato standing riconosciuto da tutti) e due esterni (consulenti la cui preparazione ed elevata reputazione sono note in azienda) per discutere la situazione e i possibili scenari futuri',
    strategicFit: 'top-down',
    kotterStep: 'coalition',
    score: 1,
    cost: 10,
  },
  {
    id: 'azione-popolazione',
    name: 'Analizzare la popolazione aziendale',
    description:
      "Analizzate i profili di tutti i collaboratori dell'azienda per individuare l'orientamento all'innovazione e la propensione al rischio, con l'obiettivo di selezionarne alcuni da coinvolgere nella task force di progetto",
    strategicFit: 'bottom-up',
    kotterStep: 'coalition',
    score: 1,
    cost: 5,
  },
  {
    id: 'azione-team',
    name: 'Comporre Team specializzati',
    description:
      "Create uno o più Team specializzati, ognuno dei quali contribuirà a tutte le fasi del progetto di evoluzione organizzativa, sotto la guida di un'unica regia generale dell'intero progetto",
    strategicFit: 'neutral',
    kotterStep: 'coalition',
    score: 1,
    cost: 5,
  },
  {
    id: 'azione-esperto',
    name: 'Coinvolgere un esperto',
    description:
      'Chiedete a un esperto (tra i "top 50 global leaders in change management") di entrare a far parte del Team di progetto, partecipando solo agli incontri "milestone',
    strategicFit: 'neutral',
    kotterStep: 'coalition',
    score: 1,
    cost: 10,
  },
  {
    id: 'azione-passato',
    name: 'Fare tesoro del passato',
    description:
      'Fate leva sulle esperienze pregresse di gestione di change management per elaborare la situazione futura e il percorso per raggiungerla',
    strategicFit: 'neutral',
    kotterStep: 'vision-and-strategy',
    score: 1,
    cost: 1,
  },
  {
    id: 'azione-strategia',
    name: 'Pianificare una strategia',
    description:
      "Fate leva su dati e report (da fonti interne/esterne affidabili) per elaborare l'indirizzo strategico e il piano dei lavori di breve e medio-lungo termine",
    strategicFit: 'neutral',
    kotterStep: 'vision-and-strategy',
    score: 1,
    cost: 1,
  },
  {
    id: 'azione-casi',
    name: 'Elaborare dei business case',
    description:
      'Elaborate, tramite focus group, dei business case che illustrino la "nuova organizzazione smart" e, sulla base di questi, redigete il piano di change management',
    strategicFit: 'bottom-up',
    kotterStep: 'vision-and-strategy',
    score: 1,
    cost: 5,
    isPU: true,
  },
  {
    id: 'azione-piano',
    name: 'Definire il programma di cambiamento',
    description:
      'Fate in modo che il top management definisca il piano di change management, comprese le aree di intervento, le fasi in cui sarà articolato, gli indicatori e le modalità di monitoraggio continuo',
    strategicFit: 'top-down',
    kotterStep: 'vision-and-strategy',
    score: 1,
    cost: 10,
  },
  {
    id: 'azione-esempi',
    name: 'Diffondere esempi virtuosi',
    description:
      'Diffondete esempi virtuosi di smart organization ("walk the talk"), eventualmente personalizzati per ciascun gruppo di stakeholder aziendali',
    strategicFit: 'neutral',
    kotterStep: 'communicate',
    score: 1,
    cost: 5,
    isPEOU: true,
  },
  {
    id: 'azione-roadshow',
    name: 'Organizzare un roadshow',
    description:
      'Organizzate un roadshow, coinvolgendo i principali stakeholder, per presentare il progetto di change management, compresi i principali risultati attesi, le tappe intermedie, gli indicatori e le modalità di monitoraggio continuo',
    strategicFit: 'neutral',
    kotterStep: 'communicate',
    score: 1,
    cost: 10,
    isPU: true,
  },
  {
    id: 'azione-manager',
    name: 'Presentare il piano ai manager',
    description:
      'Programmate un incontro tra il top management e i suoi diretti riporti per fargli presentare, nel modo più chiaro possibile, la necessità e il piano di change management, nonché le modalità per la sua attuazione',
    strategicFit: 'top-down',
    kotterStep: 'communicate',
    score: 1,
    cost: 5,
    isPEOU: true,
  },
  {
    id: 'azione-benchmarking',
    name: 'Condividere il benchmarking',
    description:
      'Fate circolare tra i dipendenti un report di benchmarking che presenti le condizioni, le principali caratteristiche e la performance delle organizzazioni "smart" prese a riferimento come virtuose',
    strategicFit: 'bottom-up',
    kotterStep: 'communicate',
    score: 1,
    cost: 1,
  },
  {
    id: 'azione-allineamento',
    name: 'Allineare il Team rispetto al piano',
    description:
      'Fate in modo che il Team di progetto si confronti costantemente per allinearsi sulla pianificazione, ed eventualmente rivedere il piano alla luce dei risultati e dei feedback raccolti in itinere',
    strategicFit: 'neutral',
    kotterStep: 'empower-action',
    score: 1,
    cost: 5,
  },
  {
    id: 'azione-comportamenti',
    name: 'Indicare i comportamenti attesi',
    description:
      "Fate in modo che il top management indichi, in modo chiaro e puntuale, i comportamenti attesi dai propri collaboratori, sia durante l'intera fase di attuazione del percorso di evoluzione organizzativa, sia in seguito",
    strategicFit: 'top-down',
    kotterStep: 'empower-action',
    score: 1,
    cost: 10,
  },
  {
    id: 'azione-pilota',
    name: "Promuovere un'iniziativa pilota",
    description:
      'Focalizzate le attività di tipo operativo del piano di change management su una specifica unità organizzativa (es. funzione o dipartimento) che agisca da "pilota", per poi gradualmente implementarle sulle altre',
    strategicFit: 'bottom-up',
    kotterStep: 'empower-action',
    score: 1,
    cost: 5,
    isPU: true,
  },
  {
    id: 'azione-training',
    name: 'Organizzare sessioni di training',
    description:
      "Organizzate sessioni di formazione e training on-the-job per illustrare le policy e gli strumenti a disposizione per aumentare l'efficacia come smart worker",
    strategicFit: 'neutral',
    kotterStep: 'empower-action',
    score: 1,
    cost: 10,
    isPEOU: true,
  },
  {
    id: 'azione-visibilita',
    name: "Dare visibilità dell'avanzamento",
    description:
      "Monitorate l'avanzamento del progetto e organizzate focus group periodici per darne continua visibilità ai principali stakeholder",
    strategicFit: 'neutral',
    kotterStep: 'short-term-wins',
    score: 1,
    cost: 1,
    isPU: true,
  },
  {
    id: 'azione-risultati',
    name: 'Condividere i risultati',
    description:
      'Elaborate e diffondete un report settimanale che metta in luce i risultati ottenuti, anche se piccoli, verso il percorso per costruire una "smart organization',
    strategicFit: 'top-down',
    kotterStep: 'short-term-wins',
    score: 1,
    cost: 1,
    isPU: true,
  },
  {
    id: 'azione-testimonianze',
    name: 'Raccogliere testimonianze positive',
    description:
      "Raccogliete dei video realizzati dai dipendenti che li ritraggono in simpatiche scene di vita quotidiana in smart working, da condividere con tutta l'organizzazione",
    strategicFit: 'bottom-up',
    kotterStep: 'short-term-wins',
    score: 1,
    cost: 5,
    isPEOU: true,
  },
  {
    id: 'azione-infografiche',
    name: 'Realizzare infografiche sui benefici',
    description:
      "Realizzate e condividete delle infografiche che illustrino chiaramente e sinteticamente i benefici man mano ottenuti per l'intera azienda",
    strategicFit: 'neutral',
    kotterStep: 'short-term-wins',
    score: 1,
    cost: 1,
    isPU: true,
  },
  {
    id: 'azione-monitoraggio',
    name: 'Monitorare la performance',
    description:
      'Tramite alcune instant survey strutturate, monitorate la performance corrente rispetto a quella attesa, da discutere con i vari interlocutori',
    strategicFit: 'neutral',
    kotterStep: 'consolidate-gains',
    score: 1,
    cost: 5,
    isPEOU: true,
  },
  {
    id: 'azione-feedback',
    name: 'Restituire dei feedback',
    description:
      "Incaricate un membro del Team di progetto di formulare e restituire dei feedback rispetto all'eventuale distanza tra i risultati ottenuti e quelli attesi",
    strategicFit: 'neutral',
    kotterStep: 'consolidate-gains',
    score: 1,
    cost: 1,
  },
  {
    id: 'azione-workshop',
    name: 'Organizzare una serie di workshop',
    description:
      'Organizzate una serie di workshop per continuare a illustrare le implicazioni organizzative e operative tipiche di una smart organization (tra cui, ad esempio, come stanno evolvendo i ruoli e i nuovi comportamenti attesi)',
    strategicFit: 'bottom-up',
    kotterStep: 'consolidate-gains',
    score: 1,
    cost: 5,
    isPU: true,
  },
  {
    id: 'azione-agire',
    name: 'Identificare delle aree di intervento',
    description:
      'Identificate delle aree di intervento su cui agire in termini di "comportamenti e azioni" messi in atto rispetto a "comportamenti e azioni" ideali di una smart organization',
    strategicFit: 'top-down',
    kotterStep: 'consolidate-gains',
    score: 1,
    cost: 10,
    isPU: true,
  },
  {
    id: 'azione-risorse',
    name: 'Ribadire le risorse a supporto',
    description:
      'Ricordate, in occasione dei management meeting formali già previsti per ogni trimestre, le risorse e i supporti a disposizione di tutta la popolazione aziendale per continuare nella direzione di costruire una smart organization',
    strategicFit: 'neutral',
    kotterStep: 'anchor-change',
    score: 1,
    cost: 5,
  },
  {
    id: 'azione-riconoscimento',
    name: 'Istituire un nuovo riconoscimento',
    description:
      'Istituite un nuovo riconoscimento formale, su base annuale, delle best practice e dei comportamenti degli smart worker',
    strategicFit: 'top-down',
    kotterStep: 'anchor-change',
    score: 1,
    cost: 5,
  },
  {
    id: 'azione-onboarding',
    name: 'Aggiornare le iniziative di onboarding',
    description:
      'Integrate nelle iniziative di onboarding dei nuovi collaboratori le modalità di lavoro della smart organization',
    strategicFit: 'bottom-up',
    kotterStep: 'anchor-change',
    score: 1,
    cost: 1,
  },
  {
    id: 'azione-formalizzare',
    name: 'Formalizzare nuovi strumenti e pratiche',
    description:
      "Rivedete (ove opportuno) e formalizzate l'introduzione di nuovi strumenti e pratiche per rendere la smart organization ancora più efficace",
    strategicFit: 'neutral',
    kotterStep: 'anchor-change',
    score: 1,
    cost: 1,
  },
];
