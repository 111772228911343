export default function isSafari(): boolean {
  return (
    typeof window !== 'undefined' &&
    (/constructor/i.test(window.HTMLElement as unknown as string) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
      })(
        !window['safari'] ||
          // @ts-ignore
          (typeof safari !== 'undefined' && safari?.pushNotification)
      ))
  );
}
