import { ifValueOf, isEqualTo } from './predicates';

interface ICompareFn<T> {
  (a: T, b: T): number;
}

export const by = <T, K extends keyof T>(
  key: K,
  compareFn: ICompareFn<T[K]>
): ICompareFn<T> => {
  return (a, b) => {
    return compareFn(a[key], b[key]);
  };
};
