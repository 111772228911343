import { ifValueOf, isEqualTo } from './predicates';

interface IMapperFn<T, M> {
  (v: T, i: number, vs: T[]): M;
}

interface IReducerFn<R, T> {
  (a: R, v: T, i: number, vs: T[]): R;
}

export const identity = <T>(v: T) => v;

export const to =
  <T, K extends keyof T>(key: K) =>
  (v: T) =>
    v[key];

export const toObject =
  <T, O extends object>(mappers: { [P in keyof O]: IMapperFn<T, O[P]> }) =>
  (v: T, i: number, vs: T[]): O =>
    Object.keys(mappers).reduce(
      (r, k) => ({ ...r, [k]: mappers[k](v, i, vs) }),
      {} as O
    );

export const toCount = <T>(collection: T[]) => collection.length;
export const subtracting = (diff: number) => (v: number) => v - diff;

export const searchingIn =
  <T extends { id: string }>(collection: T[], notFound: T) =>
  (id: string) =>
    collection.find(ifValueOf('id', isEqualTo(id))) || notFound;

export const extendingWith =
  <T, K extends string, M>(key: K, mapper: IMapperFn<T, M>) =>
  (v: T, i: number, vs: T[]): T & Record<K, M> => {
    const mapped = { [key]: mapper(v, i, vs) } as Record<K, M>;
    return { ...v, ...mapped };
  };

export const each =
  <T, M>(mapper: IMapperFn<T, M>) =>
  (v: T[]): M[] => {
    return v.map(mapper);
  };

export const reducingEach =
  <R, T>(reducer: IReducerFn<R, T>, initValue: R) =>
  (v: T[]): R => {
    return v.reduce(reducer, initValue);
  };

export const tap =
  <T>(fn: (v: T, i: number, vs: T[]) => void) =>
  (v: T, i: number, vs: T[]) => {
    fn(v, i, vs);
    return v;
  };

export const log = <T>(prefix?: string) =>
  tap((v: T, i) => console.log(prefix || '', i, v));
