import { IFeed } from '../concepts/feed';
import { IGame } from '../concepts/game';
import { StoreSlice } from '../../utils/store-slice';

interface IPrivate {}

export const create: StoreSlice<IFeed & IPrivate, IGame> = (set, get) => {
  return {
    say(msg) {
      console.log(`said: ${msg}`);
    },
  };
};
