import produce from 'immer';
import { StoreSlice } from '../../utils/store-slice';
import { Documents, IDocuments } from '../concepts/documents';

export const create: StoreSlice<IDocuments> = (set, get) => {
  return {
    availableDocuments: [],
    currentActiveDocument: null,

    openDocument(document: Documents) {
      set(
        produce((s: IDocuments) => {
          s.currentActiveDocument = document;
        })
      );
    },

    closeActiveDocument() {
      set(
        produce((s: IDocuments) => {
          s.currentActiveDocument = null;
        })
      );
    },

    async releaseDocument(document: Documents) {
      set(
        produce((s: IDocuments) => {
          if (!s.availableDocuments.includes(document)) {
            s.availableDocuments.push(document);
            s.currentActiveDocument = null;
          }
        })
      );
    },
  };
};
