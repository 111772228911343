import buildAssetPath from '../../core/buildAssetPath';
import { useMessages } from '../../store/messages';

const ConversationHeading = () => {
  const t = useMessages(({ print }) => print);

  return (
    <div className="flex flex-row items-center justify-start w-full h-12 gap-2 p-4 mb-2 bg-gray-200 rounded-lg xl:h-20">
      <img
        src={buildAssetPath('images/contact.png')}
        alt="Contact"
        className="w-10 lg:w-12 xl:w-14 aspect-square"
      />
      <p className="my-auto text-sm font-light text-center text-gray-700 xl:text-base 2xl:text-lg 3xl:text-xl">
        {t('miss-change')}
      </p>
    </div>
  );
};

export default ConversationHeading;
