import { ScenarioNames } from '../concepts';
import { PlanEvaluation } from '../concepts/plan-evaluation';
import { IdealPlan } from '../objects/ideal-plan';
import { Plan } from '../objects/plan';
import { PlanEvaluator } from '../objects/plan-evaluator';

export const evaluatePlan = (
  actions: string[],
  scenario: ScenarioNames
): PlanEvaluation => {
  const iP = IdealPlan.fromScenario(scenario);
  const e = PlanEvaluator.create(iP);
  const p = Plan.create(actions);

  return {
    actions,
    clusters: p.toClusters(iP.toKotterStep()),
    maxAcceptanceRate: e.maxAcceptanceRateOf(p),
    acceptanceRate: e.acceptanceRateOf(p),
    distanceFromIdealPlan: e.distanceFromIdealPlan(p),
    dispersion: e.dispersionOf(p),
    invertedEntropy: e.invertedEntropy(p),
    lossDueToIncompleteness: e.lossDueToIncompleteness(p),
    lossDueToPUIncompleteness: e.lossDueToPUIncompleteness(p),
    lossDueToPEOUIncompleteness: e.lossDueToPEOUIncompleteness(p),
    lossDueToDisturbingActions: e.lossDueToDisturbingActions(p),
    lossDueToEntropy: e.lossDueToEntropy(p),
    preparationPhasePerformance: e.phasePerformance(p, 'preparation'),
    communicationPhasePerformance: e.phasePerformance(p, 'communication'),
    executionPhasePerformance: e.phasePerformance(p, 'execution'),
  };
};
